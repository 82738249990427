import React, { useContext, useState, useEffect, useRef } from "react"
import { FirebaseContext } from "../Firebase"
import AlgoliaToggleSaved from "../algolia/algolia-toggle-saved"
import CareerPlans from "../careers/career-plans"
import ProfileImageModal from "./portfolio-profile-image-modal"
import WallpaperImageModal from "./portfolio-wallpaper-image-modal"
import PublicProfileModal from "./portfolio-make-profile-public-modal"
import PersonalInfoForm from "./portfolio-personal-info-form"
import PortfolioBanner from "../../../static/assets/images/portfolio-banner-default.jpg"
import PortfolioProfile from "../../../static/assets/images/portfolio-profile-default.jpg"
import AssessmentPortfolio from "../assessment/assessment-porfolio.js"
import PortfolioHeading from "./portfolio-heading.js"
import { PortfolioContext } from "./PortfolioContext"
import GenerateResumeDOCX from "../resume/docxExport/generateDOCX"
import { useReactToPrint } from "react-to-print"
import ResumeTipsModal from "../resume/tips/tips-modal.js"

import Resume from "../resume/Resume.js"

import "./portfolio.css"
import { FormattedMessage } from "react-intl"

const Portfolio = () => {
  const { profile, loading, firebase, user } = useContext(FirebaseContext)

  const [isSelectorModalOpen, setIsSelectorModalOpen] = useState(false)
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false)
  const [isTipsModalOpen, setIsTipsModalOpen] = useState(false)
  const [allResumeExperiences, setAllResumeExperiences] = useState([])
  const [isResumeForcedOpen, setIsResumeForcedOpen] = useState(false)

  const portfolioRef = useRef()

  const getResumeDataAsync = async () => {
    const firebaseResumeExperiences = await firebase.getResumeData()

    if (!!firebaseResumeExperiences) {
      let filteredResumeExperiences = firebaseResumeExperiences.experiences.filter(
        experience => !!experience.id
      )

      setAllResumeExperiences(filteredResumeExperiences ?? [])
    }
  }

  const handleModalChange = status => {
    switch (status) {
      case "SELECTION":
        setIsTipsModalOpen(false)
        setIsEditorModalOpen(false)
        setIsSelectorModalOpen(true)
        break
      case "EDITING":
        setIsTipsModalOpen(false)
        setIsEditorModalOpen(true)
        setIsSelectorModalOpen(false)
        break
      case "TIPS":
        setIsTipsModalOpen(true)
        setIsEditorModalOpen(false)
        setIsSelectorModalOpen(false)
        break
      case "CLEAR":
        setIsTipsModalOpen(false)
        setIsEditorModalOpen(false)
        setIsSelectorModalOpen(false)
        break
    }
  }

  const handleResumeExport = () => {
    if (allResumeExperiences.length === 0) {
      alert("Can not export: No resume items found")
      return
    }
    GenerateResumeDOCX({
      name: `${profile?.firstName ?? ""} ${profile?.lastName ?? ""}`,
      email: user?.email,
      experiences: allResumeExperiences,
      skills: profile?.skills || [], 
      interests: profile?.interests || [],
      city: profile?.city,
      state: profile?.state
    })
  }

  const handlePrint = useReactToPrint({
    content: () => portfolioRef.current,
  })

  const handlePdfExport = () => {
    if (typeof window !== "undefined") {
      setIsResumeForcedOpen(true)

      setTimeout(function () {
        handlePrint()
        setIsResumeForcedOpen(false)
      }, 1000)
    }
  }

  useEffect(() => {
    if (!!firebase && !!user) {
      getResumeDataAsync()
    }
  }, [firebase, user])

  if (loading || !profile) {
    return (
      <>
        <div
          style={{
            textAlign: "center",
            marginTop: "10em",
            marginBottom: "10em",
            fontSize: "20px",
          }}
        >
          Loading ...
        </div>
      </>
    )
  }

  return (
    <PortfolioContext.Provider
      value={{
        isResumeForcedOpen,
        isEditorModalOpen,
        handleModalChange,
        handleResumeExport,
        handlePdfExport,
        getResumeDataAsync,
      }}
    >
      <ResumeTipsModal show={isTipsModalOpen} />
      <div ref={portfolioRef} className="print-container">
        <section className="portfolio-hero">
          <div
            className="portfolio-hero-image"
            style={{
              backgroundImage: `url(${!!profile.wallpaperImageUrl
                  ? profile.wallpaperImageUrl
                  : PortfolioBanner
                })`,
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              style={{ position: "absolute", height: "100%", width: "100%" }}
            >
              <div className="portfolio-hero-image-edit printer-none">
                <WallpaperImageModal
                  currentImage={
                    !!profile.wallpaperImageUrl
                      ? profile.wallpaperImageUrl
                      : PortfolioBanner
                  }
                />
              </div>
              <div className="portfolio-hero-profile-image">
                {!!profile.profileImageUrl ? (
                  <img
                    src={profile.profileImageUrl}
                    alt=""
                    style={{
                      maxWidth: "100%",
                      objectFit: "cover",
                      objectPosition: "center top",
                    }}
                  />
                ) : (
                  <div className="portfolio-hero-profile-image-placheholder">
                    <img src={PortfolioProfile} alt="" />
                  </div>
                )}
                <div className="portfolio-hero-profile-image-edit printer-none">
                  <ProfileImageModal
                    currentImage={
                      !!profile.profileImageUrl
                        ? profile.profileImageUrl
                        : PortfolioProfile
                    }
                  />
                </div>
              </div>
              <div className="portfolio-hero-profile-manage">
                <PublicProfileModal />
              </div>
            </div>
          </div>
        </section>

        <section className="portfolio-heading">
          <PortfolioHeading />
        </section>

        <section className="portfolio-personal">
          <PersonalInfoForm />
        </section>

        <section>
          <Resume
            allResumeExperiences={allResumeExperiences}
            isEditorModalOpen={isEditorModalOpen}
            isSelectorModalOpen={isSelectorModalOpen}
            isPublicFacing={false}
            showSkillsAndCareer={true}
          />
        </section>

        <section>
          <AssessmentPortfolio profile={profile} hasPublicToggle={true} />
        </section>
      </div>

      <section className="portfolio-plans">
        <CareerPlans hasPublicToggle={true} />
      </section>

      <AlgoliaToggleSaved heading={<FormattedMessage
        id="portfolio-saved"
        defaultMessage="Saved"
      />} />
    </PortfolioContext.Provider>
  )
}

export default Portfolio
