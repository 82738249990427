import React, { useContext, useEffect, useState, useRef } from "react"
import { Dropdown } from "semantic-ui-react"
import { FirebaseContext } from "../Firebase"
import "semantic-ui-css/components/dropdown.min.css"
import "semantic-ui-css/components/search.min.css"
import "semantic-ui-css/components/transition.min.css"
import "semantic-ui-css/components/label.min.css"
import "semantic-ui-css/components/icon.min.css"
import { FormattedMessage, useIntl } from "react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStars } from "@fortawesome/pro-solid-svg-icons"

export const defaultOptions = [
  {
    header: "true",
    key: "header-interpersonal",
    text: "Interpersonal",
    disabled: true,
  },
  { key: "communication", value: "Communication", text: "Communication" },
  {
    key: "collaboration",
    value: "Collaboration",
    text: "Collaboration",
  },
  {
    key: "time_management",
    value: "Time Management",
    text: "Time Management",
  },
  { key: "problem_solving", value: "Problem-solving", text: "Problem-solving" },
  {
    key: "attention_to_detail",
    value: "Attention to detail",
    text: "Attention to detail",
  },
  { key: "creativity", value: "Creativity", text: "Creativity" },
  {
    key: "adaptability",
    value: "Adapabtility",
    text: "Adapabtility",
  },
  {
    key: "leadership",
    value: "Leadership",
    text: "Leadership",
  },
  { key: "teamwork", value: "Teamwork", text: "Teamwork" },
  { key: "public_speaking", value: "Public Speaking", text: "Public Speaking" },
  {
    key: "presentation_skills",
    value: "Presentation Skills",
    text: "Presentation Skills",
  },
  { key: "organization", value: "Organization", text: "Organization" },
  {
    key: "analytical_skills",
    value: "Analytical skills",
    text: "Analytical skills",
  },
  { key: "decision_making", value: "Decision-making", text: "Decision-making" },
  {
    key: "conflict_resolution",
    value: "Conflict resolution",
    text: "Conflict resolution",
  },
  {
    key: "project_management",
    value: "Project management",
    text: "Project management",
  },
  {
    key: "customer_service",
    value: "Customer service",
    text: "Customer service",
  },
  {
    key: "strategic_thinking",
    value: "Strategic thinking",
    text: "Strategic thinking",
  },
  { key: "empathy", value: "Empathy", text: "Empathy" },
  {
    key: "active_listening",
    value: "Active listening",
    text: "Active listening",
  },
  { key: "networking", value: "Networking", text: "Networking" },
  { key: "multitasking", value: "Multitasking", text: "Multitasking" },
  {
    key: "critical_thinking",
    value: "Critical thinking",
    text: "Critical thinking",
  },
  { key: "flexibility", value: "Flexibility", text: "Flexibility" },
  { key: "planning", value: "Planning", text: "Planning" },
  { key: "negotiation", value: "Negotiation", text: "Negotiation" },
  { key: "diplomacy", value: "Diplomacy", text: "Diplomacy" },
  { key: "innovation", value: "Innovation", text: "Innovation" },

  {
    header: "true",
    key: "header-administrative",
    text: "Administrative",
    disabled: true,
  },
  {
    key: "microsoft_office",
    value: "Microsoft Office",
    text: "Microsoft Office",
  },
  {
    key: "data_entry",
    value: "Data Entry",
    text: "Data Entry",
  },
  {
    key: "administrative_support",
    value: "Administrative Support",
    text: "Administrative Support",
  },
  { key: "microsoft_excel", value: "Microsoft Excel", text: "Microsoft Excel" },
  { key: "microsoft_word", value: "Microsoft Word", text: "Microsoft Word" },
  {
    key: "microsoft_powerpoint",
    value: "Microsoft Powerpoint",
    text: "Microsoft Powerpoint",
  },
  { key: "typing", value: "Typing", text: "Typing" },
  { key: "filing", value: "Filing", text: "Filing" },
  { key: "scheduling", value: "Scheduling", text: "Scheduling" },
  { key: "spreadsheets", value: "Spreadsheets", text: "Spreadsheets" },
  { key: "invoicing", value: "Invoicing", text: "Invoicing" },
  { key: "accounting", value: "Accounting", text: "Accounting" },
  { key: "receptionist", value: "Receptionist", text: "Receptionist" },
  {
    key: "office_equipment",
    value: "Office Equipment",
    text: "Office Equipment",
  },
  { key: "billing", value: "Billing", text: "Billing" },
  { key: "quick_books", value: "QuickBooks", text: "QuickBooks" },
  { key: "record_keeping", value: "Record Keeping", text: "Record Keeping" },
  { key: "slack", value: "Slack", text: "Slack" },
  { key: "google_drive", value: "Google Drive", text: "Google Drive" },
  {
    key: "microsoft_outlook",
    value: "Microsoft Outlook",
    text: "Microsoft Outlook",
  },
  { key: "payroll", value: "Payroll", text: "Payroll" },
  {
    key: "accounts_payable",
    value: "Accounts Payable",
    text: "Accounts Payable",
  },

  {
    header: "true",
    key: "header-agriculture",
    text: "Agriculture",
    disabled: true,
  },
  {
    key: "equipment_maintenance",
    value: "Equipment Maintenance",
    text: "Equipment Maintenance",
  },
  {
    key: "safety_management",
    value: "Safety Management",
    text: "Safety Management",
  },
  {
    key: "equipment_operation",
    value: "Equipment Operation",
    text: "Equipment Operation",
  },
  {
    key: "plant_identification",
    value: "Plant Identification",
    text: "Plant Identification",
  },
  { key: "irrigation", value: "Irrigation", text: "Irrigation" },
  {
    key: "heavy_equipment_operation",
    value: "Heavy Equipment Operation",
    text: "Heavy Equipment Operation",
  },
  { key: "crop_management", value: "Crop Management", text: "Crop Management" },
  { key: "farm_management", value: "Farm Management", text: "Farm Management" },
  { key: "pest_control", value: "Pest Control", text: "Pest Control" },
  { key: "soil_sampling", value: "Soil Sampling", text: "Soil Sampling" },
  {
    key: "livestock_handling",
    value: "Livestock Handling",
    text: "Livestock Handling",
  },

  {
    header: "true",
    key: "header-arts-and-media",
    text: "Arts and Media",
    disabled: true,
  },
  { key: "graphic_design", value: "Graphic design", text: "Graphic design" },
  { key: "video_editing", value: "Video editing", text: "Video editing" },
  { key: "photography", value: "Photography", text: "Photography" },
  { key: "web_design", value: "Web design", text: "Web design" },
  {
    key: "social_media_marketing",
    value: "Social media marketing",
    text: "Social media marketing",
  },
  {
    key: "writing_and_editing",
    value: "Writing and editing",
    text: "Writing and editing",
  },
  { key: "audio_editing", value: "Audio editing", text: "Audio editing" },
  { key: "animation", value: "Animation", text: "Animation" },
  { key: "game_design", value: "Game design", text: "Game design" },
  { key: "illustration", value: "Illustration", text: "Illustration" },
  { key: "event_planning", value: "Event planning", text: "Event planning" },
  { key: "scriptwriting", value: "Scriptwriting", text: "Scriptwriting" },
  { key: "acting", value: "Acting", text: "Acting" },
  {
    key: "public_relations",
    value: "Public relations",
    text: "Public relations",
  },
  {
    key: "brand_management",
    value: "Brand management",
    text: "Brand management",
  },
  { key: "interior_design", value: "Interior Design", text: "Interior Design" },
  {
    key: "architectural_design",
    value: "Architectural Design",
    text: "Architectural Design",
  },
  {
    key: "search_engine_optimization_(seo)",
    value: "Search engine optimization (SEO)",
    text: "Search engine optimization (SEO)",
  },

  { header: "true", key: "header-business", text: "Business", disabled: true },

  {
    key: "financial_analysis",
    value: "Financial analysis",
    text: "Financial analysis",
  },
  { key: "accounting_business", value: "Accounting", text: "Accounting" },
  { key: "budgeting", value: "Budgeting", text: "Budgeting" },
  {
    key: "financial_forecasting",
    value: "Financial forecasting",
    text: "Financial forecasting",
  },
  { key: "data_analysis", value: "Data analysis", text: "Data analysis" },
  { key: "microsoft_excel_financial_analysis", value: "Microsoft Excel", text: "Microsoft Excel" },
  {
    key: "financial_modeling",
    value: "Financial modeling",
    text: "Financial modeling",
  },
  {
    key: "statistical_analysis",
    value: "Statistical analysis",
    text: "Statistical analysis",
  },
  { key: "market_analysis", value: "Market analysis", text: "Market analysis" },
  {
    key: "budgeting_and_financial_analysis",
    value: "Budgeting and financial analysis",
    text: "Budgeting and financial analysis",
  },
  {
    key: "data_analysis_and_reporting",
    value: "Data analysis and reporting",
    text: "Data analysis and reporting",
  },
  {
    key: "operations_management",
    value: "Operations management",
    text: "Operations management",
  },
  {
    key: "sales_and_marketing_management",
    value: "Sales and marketing management",
    text: "Sales and marketing management",
  },
  {
    key: "business_development",
    value: "Business development",
    text: "Business development",
  },
  {
    key: "supply_chain_management",
    value: "Supply chain management",
    text: "Supply chain management",
  },
  { key: "quality_control", value: "Quality control", text: "Quality control" },
  {
    key: "human_resources_management",
    value: "Human resources management",
    text: "Human resources management",
  },
  {
    key: "contract_negotiation",
    value: "Contract negotiation",
    text: "Contract negotiation",
  },
  {
    key: "vendor_management",
    value: "Vendor management",
    text: "Vendor management",
  },
  {
    key: "process_improvement",
    value: "Process improvement",
    text: "Process improvement",
  },
  {
    key: "business_intelligence",
    value: "Business intelligence",
    text: "Business intelligence",
  },
  { key: "lean_management", value: "Lean management", text: "Lean management" },
  { key: "it_management", value: "IT management", text: "IT management" },
  {
    key: "logistics_management",
    value: "Logistics management",
    text: "Logistics management",
  },
  {
    key: "inventory_management",
    value: "Inventory management",
    text: "Inventory management",
  },
  { key: "six_sigma", value: "Six Sigma", text: "Six Sigma" },

  {
    header: "true",
    key: "header-computer-programming",
    text: "Computer Programming",
    disabled: true,
  },
  { key: "java", value: "Java", text: "Java" },
  { key: "python", value: "Python", text: "Python" },
  { key: "sql", value: "SQL", text: "SQL" },
  { key: "c++", value: "C++", text: "C++" },
  { key: "javascript", value: "JavaScript", text: "JavaScript" },
  { key: "data_analysis_computer_programming", value: "Data Analysis", text: "Data Analysis" },
  { key: "html", value: "HTML", text: "HTML" },
  { key: "css", value: "CSS", text: "CSS" },
  {
    key: "machine_learning",
    value: "Machine Learning",
    text: "Machine Learning",
  },
  {
    key: "natural_language_processing",
    value: "Natural Language Processing",
    text: "Natural Language Processing",
  },
  { key: "data_science", value: "Data Science", text: "Data Science" },
  { key: "mongodb", value: "MongoDB", text: "MongoDB" },
  {
    key: "spring_framework",
    value: "Spring Framework",
    text: "Spring Framework",
  },
  { key: ".net_framework", value: ".NET Framework", text: ".NET Framework" },
  { key: "node.js", value: "Node.js", text: "Node.js" },
  { key: "react", value: "React", text: "React" },
  { key: "angularjs", value: "AngularJS", text: "AngularJS" },
  { key: "data_mining", value: "Data Mining", text: "Data Mining" },

  {
    header: "true",
    key: "header-construction",
    text: "Construction",
    disabled: true,
  },
  {
    key: "air_compressor_and_pneumatic_tool_maintenance",
    value: "Air compressor and pneumatic tool maintenance",
    text: "Air compressor and pneumatic tool maintenance",
  },
  {
    key: "construction_site_management",
    value: "Construction site management",
    text: "Construction site management",
  },
  {
    key: "automotive_repair_and_maintenance",
    value: "Automotive repair and maintenance",
    text: "Automotive repair and maintenance",
  },
  {
    key: "blueprint_analysis",
    value: "Blueprint analysis",
    text: "Blueprint analysis",
  },
  {
    key: "boilers_and_pressure_vessels_inspection_and_maintenance",
    value: "Boilers and pressure vessels inspection and maintenance",
    text: "Boilers and pressure vessels inspection and maintenance",
  },
  {
    key: "building_and_facility_maintenance",
    value: "Building and facility maintenance",
    text: "Building and facility maintenance",
  },
  {
    key: "calibration_and_maintenance_of_analytical_instruments",
    value: "Calibration and maintenance of analytical instruments",
    text: "Calibration and maintenance of analytical instruments",
  },
  {
    key: "calibration_of_scientific_and_medical_instruments",
    value: "Calibration of scientific and medical instruments",
    text: "Calibration of scientific and medical instruments",
  },
  {
    key: "cnc_machine_programming_and_operation",
    value: "CNC machine programming and operation",
    text: "CNC machine programming and operation",
  },
  {
    key: "carpentry_and_woodworking",
    value: "Carpentry and woodworking",
    text: "Carpentry and woodworking",
  },
  {
    key: "blueprint_analysis_",
    value: "Blueprint analysis ",
    text: "Blueprint analysis ",
  },
  {
    key: "equipment_operation_and_maintenance",
    value: "Equipment operation and maintenance",
    text: "Equipment operation and maintenance",
  },
  { key: "welding", value: "Welding", text: "Welding" },
  { key: "concrete_work", value: "Concrete work", text: "Concrete work" },
  {
    key: "computer-aided_design_and_drafting_(cadd)",
    value: "Computer-aided design and drafting (CADD)",
    text: "Computer-aided design and drafting (CADD)",
  },
  { key: "plumbing", value: "Plumbing", text: "Plumbing" },
  {
    key: "electrical_wiring_and_installation",
    value: "Electrical wiring and installation",
    text: "Electrical wiring and installation",
  },
  {
    key: "heavy_equipment_operation_construction",
    value: "Heavy equipment operation",
    text: "Heavy equipment operation",
  },
  { key: "crane_operation", value: "Crane operation", text: "Crane operation" },
  {
    key: "surveying_and_mapping",
    value: "Surveying and mapping",
    text: "Surveying and mapping",
  },

  {
    header: "true",
    key: "header-culinary-arts",
    text: "Culinary Arts",
    disabled: true,
  },
  { key: "food_safety", value: "Food Safety", text: "Food Safety" },
  { key: "sanitation", value: "Sanitation", text: "Sanitation" },
  { key: "recipe_creation", value: "Recipe Creation", text: "Recipe Creation" },
  { key: "knife_skills", value: "Knife Skills", text: "Knife Skills" },
  {
    key: "food_preparation",
    value: "Food Preparation",
    text: "Food Preparation",
  },
  {
    key: "cooking_techniques",
    value: "Cooking Techniques",
    text: "Cooking Techniques",
  },
  { key: "menu_planning", value: "Menu Planning", text: "Menu Planning" },
  {
    key: "recipe_reading_and_execution",
    value: "Recipe Reading and Execution",
    text: "Recipe Reading and Execution",
  },
  { key: "portion_control", value: "Portion Control", text: "Portion Control" },
  {
    key: "inventory_management_culinary_arts",
    value: "Inventory Management",
    text: "Inventory Management",
  },
  {
    key: "baking_and_pastry",
    value: "Baking and Pastry",
    text: "Baking and Pastry",
  },

  {
    header: "true",
    key: "header-education",
    text: "Education",
    disabled: true,
  },
  {
    key: "curriculum_development",
    value: "Curriculum development",
    text: "Curriculum development",
  },
  { key: "teaching", value: "Teaching", text: "Teaching" },
  {
    key: "student_assessment_and_evaluation",
    value: "Student assessment and evaluation",
    text: "Student assessment and evaluation",
  },
  {
    key: "instructional_design",
    value: "Instructional design",
    text: "Instructional design",
  },
  {
    key: "educational_technology",
    value: "Educational technology",
    text: "Educational technology",
  },
  {
    key: "research_and_analysis",
    value: "Research and analysis",
    text: "Research and analysis",
  },
  {
    key: "library_management",
    value: "Library management",
    text: "Library management",
  },
  {
    key: "cataloging_and_classification",
    value: "Cataloging and classification",
    text: "Cataloging and classification",
  },
  {
    key: "collection_development",
    value: "Collection development",
    text: "Collection development",
  },
  {
    key: "reference_services",
    value: "Reference services",
    text: "Reference services",
  },

  {
    header: "true",
    key: "header-engineering",
    text: "Engineering",
    disabled: true,
  },
  { key: "autocad", value: "AutoCAD", text: "AutoCAD" },
  {
    key: "microsoft_office_engineering",
    value: "Microsoft Office",
    text: "Microsoft Office",
  },
  { key: "revit", value: "Revit", text: "Revit" },
  { key: "solidworks", value: "SolidWorks", text: "SolidWorks" },
  { key: "matlab", value: "MATLAB", text: "MATLAB" },
  { key: "python_engineering", value: "Python", text: "Python" },
  { key: "java_engineering", value: "Java", text: "Java" },
  { key: "c++_engineering", value: "C++", text: "C++" },
  {
    key: "finite_element_analysis",
    value: "Finite Element Analysis",
    text: "Finite Element Analysis",
  },
  { key: "hydraulics", value: "Hydraulics", text: "Hydraulics" },
  { key: "thermodynamics", value: "Thermodynamics", text: "Thermodynamics" },
  { key: "instrumentation", value: "Instrumentation", text: "Instrumentation" },
  { key: "control_systems", value: "Control Systems", text: "Control Systems" },
  { key: "water_resources", value: "Water Resources", text: "Water Resources" },
  { key: "data_analysis_engineering", value: "Data Analysis", text: "Data Analysis" },
  {
    key: "electron_diffraction_machine",
    value: "Electron Diffraction Machine",
    text: "Electron Diffraction Machine",
  },
  {
    key: "scanning_electron_microscope",
    value: "Scanning Electron Microscope",
    text: "Scanning Electron Microscope",
  },
  { key: "cnc_machining", value: "CNC Machining", text: "CNC Machining" },
  {
    key: "mechanical_design",
    value: "Mechanical Design",
    text: "Mechanical Design",
  },
  {
    key: "electrical_design",
    value: "Electrical Design",
    text: "Electrical Design",
  },
  { key: "fluid_mechanics", value: "Fluid Mechanics", text: "Fluid Mechanics" },
  { key: "robotics", value: "Robotics", text: "Robotics" },

  {
    header: "true",
    key: "header-healthcare",
    text: "HealthCare",
    disabled: true,
  },
  {
    key: "surgical_procedures_and_techniques",
    value: "Surgical procedures and techniques",
    text: "Surgical procedures and techniques",
  },
  {
    key: "patient_assessment_and_evaluation",
    value: "Patient assessment and evaluation",
    text: "Patient assessment and evaluation",
  },
  {
    key: "clinical_procedures_and_protocols",
    value: "Clinical procedures and protocols",
    text: "Clinical procedures and protocols",
  },
  {
    key: "diagnostic_imaging_technology",
    value: "Diagnostic imaging technology",
    text: "Diagnostic imaging technology",
  },
  {
    key: "pharmacology_and_medication_administration",
    value: "Pharmacology and medication administration",
    text: "Pharmacology and medication administration",
  },
  {
    key: "infection_control_and_prevention",
    value: "Infection control and prevention",
    text: "Infection control and prevention",
  },
  {
    key: "cardiopulmonary_resuscitation_(cpr)",
    value: "Cardiopulmonary resuscitation (CPR)",
    text: "Cardiopulmonary resuscitation (CPR)",
  },
  {
    key: "anatomy_and_physiology",
    value: "Anatomy and physiology",
    text: "Anatomy and physiology",
  },
  {
    key: "nursing_interventions_and_care_planning",
    value: "Nursing interventions and care planning",
    text: "Nursing interventions and care planning",
  },
  {
    key: "radiologic_technology",
    value: "Radiologic technology",
    text: "Radiologic technology",
  },
  {
    key: "laboratory_techniques_and_procedures",
    value: "Laboratory techniques and procedures",
    text: "Laboratory techniques and procedures",
  },
  { key: "patient_care", value: "Patient Care", text: "Patient Care" },
  {
    key: "medical_terminology",
    value: "Medical Terminology",
    text: "Medical Terminology",
  },
  {
    key: "vital_signs_measurement",
    value: "Vital Signs Measurement",
    text: "Vital Signs Measurement",
  },
  {
    key: "medical_records_management",
    value: "Medical Records Management",
    text: "Medical Records Management",
  },
  { key: "medical_coding", value: "Medical Coding", text: "Medical Coding" },
  { key: "first_aid", value: "First Aid", text: "First Aid" },
  {
    key: "infection_control",
    value: "Infection Control",
    text: "Infection Control",
  },
  {
    key: "electronic_health_records_(ehr)",
    value: "Electronic Health Records (EHR)",
    text: "Electronic Health Records (EHR)",
  },
  { key: "phlebotomy", value: "Phlebotomy", text: "Phlebotomy" },
  {
    key: "cardiopulmonary_resuscitation_(cpr)_healthcare",
    value: "Cardiopulmonary Resuscitation (CPR)",
    text: "Cardiopulmonary Resuscitation (CPR)",
  },
  { key: "medical_billing", value: "Medical Billing", text: "Medical Billing" },

  { header: "true", key: "header-legal", text: "Legal", disabled: true },
  { key: "legal_research", value: "Legal research", text: "Legal research" },
  {
    key: "writing_legal_documents",
    value: "Writing legal documents",
    text: "Writing legal documents",
  },
  {
    key: "analyzing_legal_documents",
    value: "Analyzing legal documents",
    text: "Analyzing legal documents",
  },
  { key: "litigation", value: "Litigation", text: "Litigation" },
  {
    key: "drafting_legal_documents",
    value: "Drafting legal documents",
    text: "Drafting legal documents",
  },
  { key: "legal_writing", value: "Legal writing", text: "Legal writing" },
  {
    key: "contract_negotiation_legal",
    value: "Contract negotiation",
    text: "Contract negotiation",
  },
  {
    key: "contract_drafting",
    value: "Contract drafting",
    text: "Contract drafting",
  },
  { key: "trial_advocacy", value: "Trial advocacy", text: "Trial advocacy" },
  {
    key: "legal_compliance",
    value: "Legal compliance",
    text: "Legal compliance",
  },
  { key: "legal_analysis", value: "Legal analysis", text: "Legal analysis" },
  { key: "legal_drafting", value: "Legal drafting", text: "Legal drafting" },
  { key: "contract_review", value: "Contract review", text: "Contract review" },
  {
    key: "legal_documentation",
    value: "Legal documentation",
    text: "Legal documentation",
  },
  {
    key: "intellectual_property_law",
    value: "Intellectual property law",
    text: "Intellectual property law",
  },
  { key: "legal_issues", value: "Legal issues", text: "Legal issues" },

  {
    header: "true",
    key: "header-manufacturing",
    text: "Manufacturing",
    disabled: true,
  },
  {
    key: "machine_operation",
    value: "Machine operation",
    text: "Machine operation",
  },
  {
    key: "cleanroom_operations",
    value: "Cleanroom operations",
    text: "Cleanroom operations",
  },
  {
    key: "quality_control_and_assurance",
    value: "Quality control and assurance",
    text: "Quality control and assurance",
  },
  {
    key: "manufacturing_processes",
    value: "Manufacturing processes",
    text: "Manufacturing processes",
  },
  {
    key: "production_scheduling",
    value: "Production scheduling",
    text: "Production scheduling",
  },
  {
    key: "equipment_maintenance_and_repair",
    value: "Equipment maintenance and repair",
    text: "Equipment maintenance and repair",
  },
  {
    key: "technical_drawing_and_blueprint_interpretation",
    value: "Technical drawing and blueprint interpretation",
    text: "Technical drawing and blueprint interpretation",
  },
  {
    key: "inventory_management_manufacturing",
    value: "Inventory management",
    text: "Inventory management",
  },
  {
    key: "material_handling",
    value: "Material handling",
    text: "Material handling",
  },
  {
    key: "safety_procedures_and_protocols",
    value: "Safety procedures and protocols",
    text: "Safety procedures and protocols",
  },
  {
    key: "lean_manufacturing_principles",
    value: "Lean manufacturing principles",
    text: "Lean manufacturing principles",
  },
  { key: "welding_manufacturing", value: "Welding", text: "Welding" },
  {
    key: "computer-aided_design_(cad)",
    value: "Computer-aided design (CAD)",
    text: "Computer-aided design (CAD)",
  },
  {
    key:
      "programming_languages_for_industrial_automation_(such_as_ladder_logic,_c++,_or_python)",
    value:
      "Programming languages for industrial automation (such as ladder logic, C++, or Python)",
    text:
      "Programming languages for industrial automation (such as ladder logic, C++, or Python)",
  },
  {
    key: "robotics_and_automation_technology",
    value: "Robotics and automation technology",
    text: "Robotics and automation technology",
  },
  {
    key: "troubleshooting_and_problem-solving",
    value: "Troubleshooting and problem-solving",
    text: "Troubleshooting and problem-solving",
  },
  {
    key: "statistical_process_control",
    value: "Statistical process control",
    text: "Statistical process control",
  },
  {
    key: "data_analysis_and_interpretation",
    value: "Data analysis and interpretation",
    text: "Data analysis and interpretation",
  },
  {
    key: "environmental,_health,_and_safety_regulations",
    value: "Environmental, health, and safety regulations",
    text: "Environmental, health, and safety regulations",
  },
  {
    key: "calibration_of_measurement_instruments",
    value: "Calibration of measurement instruments",
    text: "Calibration of measurement instruments",
  },
  {
    key: "electrical_wiring_and_circuitry",
    value: "Electrical wiring and circuitry",
    text: "Electrical wiring and circuitry",
  },
  {
    key: "pneumatic_and_hydraulic_systems",
    value: "Pneumatic and hydraulic systems",
    text: "Pneumatic and hydraulic systems",
  },
  {
    key: "machining_and_tooling",
    value: "Machining and tooling",
    text: "Machining and tooling",
  },
  {
    key: "injection_molding",
    value: "Injection molding",
    text: "Injection molding",
  },
  { key: "extrusion", value: "Extrusion", text: "Extrusion" },
  {
    key: "metal_fabrication",
    value: "Metal fabrication",
    text: "Metal fabrication",
  },
  {
    key: "assembly_line_operations",
    value: "Assembly line operations",
    text: "Assembly line operations",
  },
  {
    key: "packaging_and_labeling",
    value: "Packaging and labeling",
    text: "Packaging and labeling",
  },
  {
    key: "cnc_programming_and_operation",
    value: "CNC programming and operation",
    text: "CNC programming and operation",
  },
  { key: "composites", value: "Composites", text: "Composites" },
  { key: "vacuum_systems", value: "Vacuum systems", text: "Vacuum systems" },
  {
    key: "process_improvement_methodologies_(such_as_six_sigma_or_kaizen)",
    value: "Process improvement methodologies (such as Six Sigma or Kaizen)",
    text: "Process improvement methodologies (such as Six Sigma or Kaizen)",
  },

  {
    header: "true",
    key: "header-protective-services",
    text: "Protective Services",
    disabled: true,
  },
  { key: "first_aid/cpr", value: "First Aid/CPR", text: "First Aid/CPR" },
  {
    key: "emergency_medical_response",
    value: "Emergency medical response",
    text: "Emergency medical response",
  },
  {
    key: "firearms_training",
    value: "Firearms training",
    text: "Firearms training",
  },
  { key: "surveillance", value: "Surveillance", text: "Surveillance" },
  { key: "investigation", value: "Investigation", text: "Investigation" },
  { key: "risk_assessment", value: "Risk assessment", text: "Risk assessment" },
  {
    key: "security_systems",
    value: "Security systems",
    text: "Security systems",
  },
  {
    key: "crisis_management",
    value: "Crisis management",
    text: "Crisis management",
  },
  { key: "crowd_control", value: "Crowd control", text: "Crowd control" },
  {
    key: "crime_scene_investigation",
    value: "Crime scene investigation",
    text: "Crime scene investigation",
  },
  { key: "patrol", value: "Patrol", text: "Patrol" },
  { key: "criminal_law", value: "Criminal law", text: "Criminal law" },
  { key: "traffic_control", value: "Traffic control", text: "Traffic control" },
  {
    key: "criminal_justice",
    value: "Criminal justice",
    text: "Criminal justice",
  },
  { key: "public_safety", value: "Public safety", text: "Public safety" },
  {
    key: "search_and_rescue",
    value: "Search and rescue",
    text: "Search and rescue",
  },

  { header: "true", key: "header-sales", text: "Sales", disabled: true },
  { key: "prospecting", value: "Prospecting", text: "Prospecting" },
  { key: "lead_generation", value: "Lead generation", text: "Lead generation" },
  {
    key: "customer_relationship_management_(crm)",
    value: "Customer relationship management (CRM)",
    text: "Customer relationship management (CRM)",
  },
  {
    key: "product_knowledge",
    value: "Product knowledge",
    text: "Product knowledge",
  },
  { key: "sales_closing", value: "Sales closing", text: "Sales closing" },
  {
    key: "contract_management",
    value: "Contract management",
    text: "Contract management",
  },
  {
    key: "pipeline_management",
    value: "Pipeline management",
    text: "Pipeline management",
  },
  { key: "sales_reporting", value: "Sales reporting", text: "Sales reporting" },
  { key: "sales_analytics", value: "Sales analytics", text: "Sales analytics" },
  { key: "market_research", value: "Market research", text: "Market research" },
  {
    key: "competitive_analysis",
    value: "Competitive analysis",
    text: "Competitive analysis",
  },
  {
    key: "forecasting_and_budgeting",
    value: "Forecasting and budgeting",
    text: "Forecasting and budgeting",
  },
  { key: "cold_calling", value: "Cold calling", text: "Cold calling" },
  {
    key: "consultative_selling",
    value: "Consultative selling",
    text: "Consultative selling",
  },
  { key: "sales_training", value: "Sales training", text: "Sales training" },
  {
    key: "account_management",
    value: "Account management",
    text: "Account management",
  },
  {
    key: "pricing_analysis",
    value: "Pricing analysis",
    text: "Pricing analysis",
  },
  {
    key: "territory_management",
    value: "Territory management",
    text: "Territory management",
  },
  {
    key: "business_development_sales",
    value: "Business development",
    text: "Business development",
  },
  {
    key: "sales_operations",
    value: "Sales operations",
    text: "Sales operations",
  },
  {
    key: "marketing_automation",
    value: "Marketing automation",
    text: "Marketing automation",
  },
  { key: "email_marketing", value: "Email marketing", text: "Email marketing" },
  {
    key: "social_media_marketing_sales",
    value: "Social media marketing",
    text: "Social media marketing",
  },
  {
    key: "online_advertising",
    value: "Online advertising",
    text: "Online advertising",
  },
  {
    key: "search_engine_optimization_(seo)_sales",
    value: "Search engine optimization (SEO)",
    text: "Search engine optimization (SEO)",
  },
  {
    key: "search_engine_marketing_(sem)",
    value: "Search engine marketing (SEM)",
    text: "Search engine marketing (SEM)",
  },
  {
    key: "content_marketing",
    value: "Content marketing",
    text: "Content marketing",
  },

  { header: "true", key: "header-science", text: "Science", disabled: true },
  {
    key: "artificial_intelligence",
    value: "Artificial intelligence",
    text: "Artificial intelligence",
  },
  {
    key: "r_programming_language",
    value: "R programming language",
    text: "R programming language",
  },
  {
    key: "behavioral_economics",
    value: "Behavioral economics",
    text: "Behavioral economics",
  },
  { key: "bioinformatics", value: "Bioinformatics", text: "Bioinformatics" },
  { key: "calibration", value: "Calibration", text: "Calibration" },
  {
    key: "cellular_biology_techniques",
    value: "Cellular biology techniques",
    text: "Cellular biology techniques",
  },
  { key: "data_analysis_science", value: "Data analysis", text: "Data analysis" },
  {
    key: "data_interpretation",
    value: "Data interpretation",
    text: "Data interpretation",
  },
  { key: "data_management", value: "Data management", text: "Data management" },
  { key: "data_mining_science", value: "Data mining", text: "Data mining" },
  {
    key: "data_visualization",
    value: "Data visualization",
    text: "Data visualization",
  },
  {
    key: "database_management",
    value: "Database management",
    text: "Database management",
  },
  {
    key: "decision_analysis",
    value: "Decision analysis",
    text: "Decision analysis",
  },
  {
    key: "digital_marketing",
    value: "Digital marketing",
    text: "Digital marketing",
  },
  {
    key: "econometric_modeling",
    value: "Econometric modeling",
    text: "Econometric modeling",
  },
  {
    key: "experimental_controls",
    value: "Experimental controls",
    text: "Experimental controls",
  },
  {
    key: "experimental_design",
    value: "Experimental design",
    text: "Experimental design",
  },
  {
    key: "experimental_economics",
    value: "Experimental economics",
    text: "Experimental economics",
  },

  {
    header: "true",
    key: "header-social-services",
    text: "Social Services",
    disabled: true,
  },
  { key: "case_management", value: "Case management", text: "Case management" },
  { key: "counseling", value: "Counseling", text: "Counseling" },
  {
    key: "crisis_intervention",
    value: "Crisis intervention",
    text: "Crisis intervention",
  },
  {
    key: "client_assessment",
    value: "Client assessment",
    text: "Client assessment",
  },
  { key: "advocacy", value: "Advocacy", text: "Advocacy" },
  {
    key: "group_facilitation",
    value: "Group facilitation",
    text: "Group facilitation",
  },
  { key: "data_entry_social_services", value: "Data entry", text: "Data entry" },
  {
    key: "research_and_analysis_social_services",
    value: "Research and analysis",
    text: "Research and analysis",
  },
  { key: "grant_writing", value: "Grant writing", text: "Grant writing" },
  {
    key: "program_evaluation",
    value: "Program evaluation",
    text: "Program evaluation",
  },
]

const defaultOptionsEs = [
  {
    header: "true",
    key: "header-interpersonal",
    text: "Interpersonal",
    disabled: true,
  },
  { key: "communication", value: "Communication", text: "Comunicación" },
  { key: "collaboration", value: "Collaboration", text: "Colaboración" },
  {
    key: "time_management",
    value: "Time Management",
    text: "Gestión del tiempo",
  },
  {
    key: "problem_solving",
    value: "Problem-solving",
    text: "Resolución de problemas",
  },
  {
    key: "attention_to_detail",
    value: "Attention to detail",
    text: "Atención al detalle",
  },
  { key: "creativity", value: "Creativity", text: "Creatividad" },
  { key: "adaptability", value: "Adapabtility", text: "Adaptación" },
  { key: "leadership", value: "Leadership", text: "Liderazgo" },
  { key: "teamwork", value: "Teamwork", text: "Trabajo en Equipo" },
  {
    key: "public_speaking",
    value: "Public Speaking",
    text: "Hablar en público",
  },
  {
    key: "presentation_skills",
    value: "Presentation Skills",
    text: "Habilidades de presentación",
  },
  { key: "organization", value: "Organization", text: "Organización" },
  {
    key: "analytical_skills",
    value: "Analytical skills",
    text: "Habilidades analíticas",
  },
  {
    key: "decision_making",
    value: "Decision-making",
    text: "Toma de decisiones",
  },
  {
    key: "conflict_resolution",
    value: "Conflict resolution",
    text: "Resolución de conflictos",
  },
  {
    key: "project_management",
    value: "Project management",
    text: "Gestión de proyectos",
  },
  {
    key: "customer_service",
    value: "Customer service",
    text: "Servicio al cliente",
  },
  {
    key: "strategic_thinking",
    value: "Strategic thinking",
    text: "Pensamiento estratégico",
  },
  { key: "empathy", value: "Empathy", text: "Empatía" },
  {
    key: "active_listening",
    value: "Active listening",
    text: "Escucha activa",
  },
  { key: "networking", value: "Networking", text: "Gestión de redes" },
  { key: "multitasking", value: "Multitasking", text: "Multitarea" },
  {
    key: "critical_thinking",
    value: "Critical thinking",
    text: "Pensamiento crítico",
  },
  { key: "flexibility", value: "Flexibility", text: "Flexibilidad" },
  { key: "planning", value: "Planning", text: "Planificación" },
  { key: "negotiation", value: "Negotiation", text: "Negociación" },
  { key: "diplomacy", value: "Diplomacy", text: "Diplomacia" },
  { key: "innovation", value: "Innovation", text: "Innovación" },
  {
    header: "true",
    key: "header-administrative",
    text: "Administrativo",
    disabled: true,
  },
  {
    key: "microsoft_office",
    value: "Microsoft Office",
    text: "Microsoft Office",
  },
  { key: "data_entry", value: "Data Entry", text: "Entrada de datos" },
  {
    key: "administrative_support",
    value: "Administrative Support",
    text: "Apoyo Administrativo",
  },
  { key: "microsoft_excel", value: "Microsoft Excel", text: "Microsoft Excel" },
  { key: "microsoft_word", value: "Microsoft Word", text: "Microsoft Word" },
  {
    key: "microsoft_powerpoint",
    value: "Microsoft Powerpoint",
    text: "Microsoft Powerpoint",
  },
  { key: "typing", value: "Typing", text: "Mecanografía" },
  { key: "filing", value: "Filing", text: "Limado" },
  { key: "scheduling", value: "Scheduling", text: "Planificación" },
  { key: "spreadsheets", value: "Spreadsheets", text: "Cálculo" },
  { key: "invoicing", value: "Invoicing", text: "Facturación" },
  { key: "accounting", value: "Accounting", text: "Contabilidad" },
  { key: "receptionist", value: "Receptionist", text: "Recepcionista" },
  {
    key: "office_equipment",
    value: "Office Equipment",
    text: "Equipo de oficina",
  },
  { key: "billing", value: "Billing", text: "Facturación" },
  { key: "quick_books", value: "QuickBooks", text: "QuickBooks" },
  {
    key: "record_keeping",
    value: "Record Keeping",
    text: "Mantenimiento de registros",
  },
  { key: "slack", value: "Slack", text: "Flojo" },
  { key: "google_drive", value: "Google Drive", text: "Google Drive" },
  {
    key: "microsoft_outlook",
    value: "Microsoft Outlook",
    text: "Microsoft Outlook",
  },
  { key: "payroll", value: "Payroll", text: "Nómina" },
  {
    key: "accounts_payable",
    value: "Accounts Payable",
    text: "Cuentas a pagar",
  },
  {
    header: "true",
    key: "header-agriculture",
    text: "Agricultura",
    disabled: true,
  },
  {
    key: "equipment_maintenance",
    value: "Equipment Maintenance",
    text: "Mantenimiento de equipos",
  },
  {
    key: "safety_management",
    value: "Safety Management",
    text: "Gestión de la seguridad",
  },
  {
    key: "equipment_operation",
    value: "Equipment Operation",
    text: "Operación del equipo",
  },
  {
    key: "plant_identification",
    value: "Plant Identification",
    text: "Identificación de plantas",
  },
  { key: "irrigation", value: "Irrigation", text: "Riego" },
  {
    key: "heavy_equipment_operation",
    value: "Heavy Equipment Operation",
    text: "Operación de equipo pesado",
  },
  {
    key: "crop_management",
    value: "Crop Management",
    text: "Manejo de cultivos",
  },
  {
    key: "farm_management",
    value: "Farm Management",
    text: "Gestión de fincas",
  },
  { key: "pest_control", value: "Pest Control", text: "Control de plagas" },
  { key: "soil_sampling", value: "Soil Sampling", text: "Muestreo de suelos" },
  {
    key: "livestock_handling",
    value: "Livestock Handling",
    text: "Manejo de ganado",
  },
  {
    header: "true",
    key: "header-arts-and-media",
    text: "Arte y Medios de Comunicación",
    disabled: true,
  },
  { key: "graphic_design", value: "Graphic design", text: "Diseño gráfico" },
  { key: "video_editing", value: "Video editing", text: "Edición de vídeo" },
  { key: "photography", value: "Photography", text: "Fotografía" },
  { key: "web_design", value: "Web design", text: "Diseño web" },
  {
    key: "social_media_marketing",
    value: "Social media marketing",
    text: "Marketing en redes sociales",
  },
  {
    key: "writing_and_editing",
    value: "Writing and editing",
    text: "Escritura y edición",
  },
  { key: "audio_editing", value: "Audio editing", text: "Edición de audio" },
  { key: "animation", value: "Animation", text: "Animación" },
  { key: "game_design", value: "Game design", text: "Diseño de juegos" },
  { key: "illustration", value: "Illustration", text: "Ilustración" },
  {
    key: "event_planning",
    value: "Event planning",
    text: "Planificación de eventos",
  },
  { key: "scriptwriting", value: "Scriptwriting", text: "Guión" },
  { key: "acting", value: "Acting", text: "Interino" },
  {
    key: "public_relations",
    value: "Public relations",
    text: "Relaciones públicas",
  },
  {
    key: "brand_management",
    value: "Brand management",
    text: "Gestión de marca",
  },
  {
    key: "interior_design",
    value: "Interior Design",
    text: "Diseño de Interiores",
  },
  {
    key: "architectural_design",
    value: "Architectural Design",
    text: "Diseño Arquitectónico",
  },
  {
    key: "search_engine_optimization_(seo)",
    value: "Search engine optimization (SEO)",
    text: "Optimización de motores de búsqueda (SEO)",
  },
  { header: "true", key: "header-business", text: "Negocio", disabled: true },
  {
    key: "financial_analysis",
    value: "Financial analysis",
    text: "Análisis financiero",
  },
  { key: "accounting_business", value: "Accounting", text: "Contabilidad" },
  { key: "budgeting", value: "Budgeting", text: "Presupuestación" },
  {
    key: "financial_forecasting",
    value: "Financial forecasting",
    text: "Previsión financiera",
  },
  { key: "data_analysis_business", value: "Data analysis", text: "Análisis de datos" },
  { key: "microsoft_excel_analysis", value: "Microsoft Excel", text: "Microsoft Excel" },
  {
    key: "financial_modeling",
    value: "Financial modeling",
    text: "Modelización financiera",
  },
  {
    key: "statistical_analysis",
    value: "Statistical analysis",
    text: "Análisis estadístico",
  },
  {
    key: "market_analysis",
    value: "Market analysis",
    text: "Análisis de mercado",
  },
  {
    key: "budgeting_and_financial_analysis",
    value: "Budgeting and financial analysis",
    text: "Presupuestación y análisis financiero",
  },
  {
    key: "data_analysis_and_reporting",
    value: "Data analysis and reporting",
    text: "Análisis de datos e informes",
  },
  {
    key: "operations_management",
    value: "Operations management",
    text: "Gestión de operaciones",
  },
  {
    key: "sales_and_marketing_management",
    value: "Sales and marketing management",
    text: "Gestión de ventas y marketing",
  },
  {
    key: "business_development",
    value: "Business development",
    text: "Desarrollo de negocio",
  },
  {
    key: "supply_chain_management",
    value: "Supply chain management",
    text: "Gestión de la cadena de suministro",
  },
  {
    key: "quality_control",
    value: "Quality control",
    text: "Control de calidad",
  },
  {
    key: "human_resources_management",
    value: "Human resources management",
    text: "Gestión de recursos humanos",
  },
  {
    key: "contract_negotiation",
    value: "Contract negotiation",
    text: "Negociación de contratos",
  },
  {
    key: "vendor_management",
    value: "Vendor management",
    text: "Gestión de proveedores",
  },
  {
    key: "process_improvement",
    value: "Process improvement",
    text: "Mejora de procesos",
  },
  {
    key: "business_intelligence",
    value: "Business intelligence",
    text: "Inteligencia de negocios",
  },
  { key: "lean_management", value: "Lean management", text: "Gestión Lean" },
  { key: "it_management", value: "IT management", text: "Gestión de TI" },
  {
    key: "logistics_management",
    value: "Logistics management",
    text: "Gestión logística",
  },
  {
    key: "inventory_management",
    value: "Inventory management",
    text: "Gestión de inventario",
  },
  { key: "six_sigma", value: "Six Sigma", text: "Seis Sigma" },
  {
    header: "true",
    key: "header-computer-programming",
    text: "Programación de Computadoras",
    disabled: true,
  },
  { key: "java", value: "Java", text: "Java" },
  { key: "python", value: "Python", text: "Pitón" },
  { key: "sql", value: "SQL", text: ".SQL" },
  { key: "c++", value: "C++", text: "C++" },
  { key: "javascript", value: "JavaScript", text: "JavaScript" },
  { key: "data_analysis_computer_programming", value: "Data Analysis", text: "Análisis de datos" },
  { key: "html", value: "HTML", text: ".HTML" },
  { key: "css", value: "CSS", text: ".CSS" },
  {
    key: "machine_learning",
    value: "Machine Learning",
    text: "Aprendizaje automático",
  },
  {
    key: "natural_language_processing",
    value: "Natural Language Processing",
    text: "Procesamiento del lenguaje natural",
  },
  { key: "data_science", value: "Data Science", text: "Ciencia de datos" },
  { key: "mongodb", value: "MongoDB", text: "MongoDB" },
  {
    key: "spring_framework",
    value: "Spring Framework",
    text: "Marco de primavera",
  },
  { key: ".net_framework", value: ".NET Framework", text: ".NET Framework" },
  { key: "node.js", value: "Node.js", text: "Nodo.js" },
  { key: "react", value: "React", text: "Reaccionar" },
  { key: "angularjs", value: "AngularJS", text: "AngularJS" },
  { key: "data_mining", value: "Data Mining", text: "Minería de datos" },
  {
    header: "true",
    key: "header-construction",
    text: "Construcción",
    disabled: true,
  },
  {
    key: "air_compressor_and_pneumatic_tool_maintenance",
    value: "Air compressor and pneumatic tool maintenance",
    text: "Mantenimiento de compresores de aire y herramientas neumáticas",
  },
  {
    key: "construction_site_management",
    value: "Construction site management",
    text: "Gestión de obras",
  },
  {
    key: "automotive_repair_and_maintenance",
    value: "Automotive repair and maintenance",
    text: "Reparación y mantenimiento de automóviles",
  },
  {
    key: "blueprint_analysis",
    value: "Blueprint analysis",
    text: "Análisis de planos",
  },
  {
    key: "boilers_and_pressure_vessels_inspection_and_maintenance",
    value: "Boilers and pressure vessels inspection and maintenance",
    text: "Inspección y mantenimiento de calderas y recipientes a presión",
  },
  {
    key: "building_and_facility_maintenance",
    value: "Building and facility maintenance",
    text: "Mantenimiento de edificios e instalaciones",
  },
  {
    key: "calibration_and_maintenance_of_analytical_instruments",
    value: "Calibration and maintenance of analytical instruments",
    text: "Calibración y mantenimiento de instrumentos analíticos",
  },
  {
    key: "calibration_of_scientific_and_medical_instruments",
    value: "Calibration of scientific and medical instruments",
    text: "Calibración de instrumentos científicos y médicos",
  },
  {
    key: "cnc_machine_programming_and_operation",
    value: "CNC machine programming and operation",
    text: "Programación y operación de máquinas CNC",
  },
  {
    key: "carpentry_and_woodworking",
    value: "Carpentry and woodworking",
    text: "Carpintería y carpintería",
  },
  {
    key: "blueprint_analysis_",
    value: "Blueprint analysis ",
    text: "Análisis de planos ",
  },
  {
    key: "equipment_operation_and_maintenance",
    value: "Equipment operation and maintenance",
    text: "Operación y mantenimiento de equipos",
  },
  { key: "welding", value: "Welding", text: "Soldadura" },
  { key: "concrete_work", value: "Concrete work", text: "Obra concreta" },
  {
    key: "computer-aided_design_and_drafting_(cadd)",
    value: "Computer-aided design and drafting (CADD)",
    text: "Diseño y dibujo asistido por ordenador (CADD)",
  },
  { key: "plumbing", value: "Plumbing", text: "Plomería" },
  {
    key: "electrical_wiring_and_installation",
    value: "Electrical wiring and installation",
    text: "Cableado eléctrico e instalación",
  },
  {
    key: "heavy_equipment_operation_construction",
    value: "Heavy equipment operation",
    text: "Operación de equipos pesados",
  },
  {
    key: "crane_operation",
    value: "Crane operation",
    text: "Operación de grúa",
  },
  {
    key: "surveying_and_mapping",
    value: "Surveying and mapping",
    text: "Topografía y cartografía",
  },
  {
    header: "true",
    key: "header-culinary-arts",
    text: "Artes culinarias",
    disabled: true,
  },
  { key: "food_safety", value: "Food Safety", text: "Seguridad alimentaria" },
  { key: "sanitation", value: "Sanitation", text: "Saneamiento" },
  {
    key: "recipe_creation",
    value: "Recipe Creation",
    text: "Creación de recetas",
  },
  {
    key: "knife_skills",
    value: "Knife Skills",
    text: "Habilidades con el cuchillo",
  },
  {
    key: "food_preparation",
    value: "Food Preparation",
    text: "Preparación de alimentos",
  },
  {
    key: "cooking_techniques",
    value: "Cooking Techniques",
    text: "Técnicas de cocción",
  },
  {
    key: "menu_planning",
    value: "Menu Planning",
    text: "Planificación de menús",
  },
  {
    key: "recipe_reading_and_execution",
    value: "Recipe Reading and Execution",
    text: "Lectura y ejecución de recetas",
  },
  {
    key: "portion_control",
    value: "Portion Control",
    text: "Control de porciones",
  },
  {
    key: "inventory_management_culinary_arts",
    value: "Inventory Management",
    text: "Gestión de inventario",
  },
  {
    key: "baking_and_pastry",
    value: "Baking and Pastry",
    text: "Panadería y pastelería",
  },
  {
    header: "true",
    key: "header-education",
    text: "Educación",
    disabled: true,
  },
  {
    key: "curriculum_development",
    value: "Curriculum development",
    text: "Desarrollo curricular",
  },
  { key: "teaching", value: "Teaching", text: "Enseñanza" },
  {
    key: "student_assessment_and_evaluation",
    value: "Student assessment and evaluation",
    text: "Evaluación y evaluación de los estudiantes",
  },
  {
    key: "instructional_design",
    value: "Instructional design",
    text: "Diseño instruccional",
  },
  {
    key: "educational_technology",
    value: "Educational technology",
    text: "Tecnología educativa",
  },
  {
    key: "research_and_analysis",
    value: "Research and analysis",
    text: "Investigación y análisis",
  },
  {
    key: "library_management",
    value: "Library management",
    text: "Gestión bibliotecaria",
  },
  {
    key: "cataloging_and_classification",
    value: "Cataloging and classification",
    text: "Catalogación y clasificación",
  },
  {
    key: "collection_development",
    value: "Collection development",
    text: "Desarrollo de colecciones",
  },
  {
    key: "reference_services",
    value: "Reference services",
    text: "Servicios de referencia",
  },
  {
    header: "true",
    key: "header-engineering",
    text: "Ingeniería",
    disabled: true,
  },
  { key: "autocad", value: "AutoCAD", text: "AutoCAD" },
  {
    key: "microsoft_office_engineering",
    value: "Microsoft Office",
    text: "Microsoft Office",
  },
  { key: "revit", value: "Revit", text: "Revit" },
  { key: "solidworks", value: "SolidWorks", text: "SolidWorks" },
  { key: "matlab", value: "MATLAB", text: "MATLAB" },
  { key: "python_engineering", value: "Python", text: "Pitón" },
  { key: "java_engineering", value: "Java", text: "Java" },
  { key: "c++_engineering", value: "C++", text: "C++" },
  {
    key: "finite_element_analysis",
    value: "Finite Element Analysis",
    text: "Análisis de elementos finitos",
  },
  { key: "hydraulics", value: "Hydraulics", text: "Hidráulica" },
  { key: "thermodynamics", value: "Thermodynamics", text: "Termodinámica" },
  { key: "instrumentation", value: "Instrumentation", text: "Instrumentación" },
  {
    key: "control_systems",
    value: "Control Systems",
    text: "Sistemas de control",
  },
  {
    key: "water_resources",
    value: "Water Resources",
    text: "Recursos hídricos",
  },
  { key: "data_analysis_engineering", value: "Data Analysis", text: "Análisis de datos" },
  {
    key: "electron_diffraction_machine",
    value: "Electron Diffraction Machine",
    text: "Máquina de difracción de electrones",
  },
  {
    key: "scanning_electron_microscope",
    value: "Scanning Electron Microscope",
    text: "Microscopio electrónico de barrido",
  },
  { key: "cnc_machining", value: "CNC Machining", text: "Mecanizado CNC" },
  {
    key: "mechanical_design",
    value: "Mechanical Design",
    text: "Diseño mecánico",
  },
  {
    key: "electrical_design",
    value: "Electrical Design",
    text: "Diseño eléctrico",
  },
  {
    key: "fluid_mechanics",
    value: "Fluid Mechanics",
    text: "Mecánica de fluidos",
  },
  { key: "robotics", value: "Robotics", text: "Robótica" },
  {
    header: "true",
    key: "header-healthcare",
    text: "Atención sanitaria",
    disabled: true,
  },
  {
    key: "surgical_procedures_and_techniques",
    value: "Surgical procedures and techniques",
    text: "Procedimientos y técnicas quirúrgicas",
  },
  {
    key: "patient_assessment_and_evaluation",
    value: "Patient assessment and evaluation",
    text: "Evaluación y valoración del paciente",
  },
  {
    key: "clinical_procedures_and_protocols",
    value: "Clinical procedures and protocols",
    text: "Procedimientos y protocolos clínicos",
  },
  {
    key: "diagnostic_imaging_technology",
    value: "Diagnostic imaging technology",
    text: "Tecnología de diagnóstico por imágenes",
  },
  {
    key: "pharmacology_and_medication_administration",
    value: "Pharmacology and medication administration",
    text: "Farmacología y administración de medicamentos",
  },
  {
    key: "infection_control_and_prevention",
    value: "Infection control and prevention",
    text: "Control y prevención de infecciones",
  },
  {
    key: "cardiopulmonary_resuscitation_(cpr)",
    value: "Cardiopulmonary resuscitation (CPR)",
    text: "Reanimación cardiopulmonar (RCP)",
  },
  {
    key: "anatomy_and_physiology",
    value: "Anatomy and physiology",
    text: "Anatomía y fisiología",
  },
  {
    key: "nursing_interventions_and_care_planning",
    value: "Nursing interventions and care planning",
    text: "Intervenciones de enfermería y planificación de la atención",
  },
  {
    key: "radiologic_technology",
    value: "Radiologic technology",
    text: "Tecnología radiológica",
  },
  {
    key: "laboratory_techniques_and_procedures",
    value: "Laboratory techniques and procedures",
    text: "Técnicas y procedimientos de laboratorio",
  },
  { key: "patient_care", value: "Patient Care", text: "Atención al paciente" },
  {
    key: "medical_terminology",
    value: "Medical Terminology",
    text: "Terminología médica",
  },
  {
    key: "vital_signs_measurement",
    value: "Vital Signs Measurement",
    text: "Medición de signos vitales",
  },
  {
    key: "medical_records_management",
    value: "Medical Records Management",
    text: "Gestión de registros médicos",
  },
  {
    key: "medical_coding",
    value: "Medical Coding",
    text: "Codificación médica",
  },
  { key: "first_aid", value: "First Aid", text: "Primeros auxilios" },
  {
    key: "infection_control",
    value: "Infection Control",
    text: "Control de infecciones",
  },
  {
    key: "electronic_health_records_(ehr)",
    value: "Electronic Health Records (EHR)",
    text: "Registros electrónicos de salud (EHR)",
  },
  { key: "phlebotomy", value: "Phlebotomy", text: "Flebotomía" },
  {
    key: "cardiopulmonary_resuscitation_(cpr)__healthcare",
    value: "Cardiopulmonary Resuscitation (CPR)",
    text: "Reanimación cardiopulmonar (RCP)",
  },
  {
    key: "medical_billing",
    value: "Medical Billing",
    text: "Facturación médica",
  },
  { header: "true", key: "header-legal", text: "Legal", disabled: true },
  {
    key: "legal_research",
    value: "Legal research",
    text: "Investigación jurídica",
  },
  {
    key: "writing_legal_documents",
    value: "Writing legal documents",
    text: "Redacción de documentos legales",
  },
  {
    key: "analyzing_legal_documents",
    value: "Analyzing legal documents",
    text: "Análisis de documentos legales",
  },
  { key: "litigation", value: "Litigation", text: "Litigación" },
  {
    key: "drafting_legal_documents",
    value: "Drafting legal documents",
    text: "Redacción de documentos legales",
  },
  { key: "legal_writing", value: "Legal writing", text: "Redacción legal" },
  {
    key: "contract_negotiation_legal",
    value: "Contract negotiation",
    text: "Negociación de contratos",
  },
  {
    key: "contract_drafting",
    value: "Contract drafting",
    text: "Redacción de contratos",
  },
  {
    key: "trial_advocacy",
    value: "Trial advocacy",
    text: "Abogacía en juicios",
  },
  {
    key: "legal_compliance",
    value: "Legal compliance",
    text: "Cumplimiento legal",
  },
  { key: "legal_analysis", value: "Legal analysis", text: "Análisis jurídico" },
  {
    key: "legal_drafting",
    value: "Legal drafting",
    text: "Redacción jurídica",
  },
  {
    key: "contract_review",
    value: "Contract review",
    text: "Revisión de contratos",
  },
  {
    key: "legal_documentation",
    value: "Legal documentation",
    text: "Documentación legal",
  },
  {
    key: "intellectual_property_law",
    value: "Intellectual property law",
    text: "Derecho de la propiedad intelectual",
  },
  { key: "legal_issues", value: "Legal issues", text: "Cuestiones jurídicas" },
  {
    header: "true",
    key: "header-manufacturing",
    text: "Fabricación",
    disabled: true,
  },
  {
    key: "machine_operation",
    value: "Machine operation",
    text: "Funcionamiento de la máquina",
  },
  {
    key: "cleanroom_operations",
    value: "Cleanroom operations",
    text: "Operaciones de sala limpia",
  },
  {
    key: "quality_control_and_assurance",
    value: "Quality control and assurance",
    text: "Control y aseguramiento de la calidad",
  },
  {
    key: "manufacturing_processes",
    value: "Manufacturing processes",
    text: "Procesos de fabricación",
  },
  {
    key: "production_scheduling",
    value: "Production scheduling",
    text: "Programación de la producción",
  },
  {
    key: "equipment_maintenance_and_repair",
    value: "Equipment maintenance and repair",
    text: "Mantenimiento y reparación de equipos",
  },
  {
    key: "technical_drawing_and_blueprint_interpretation",
    value: "Technical drawing and blueprint interpretation",
    text: "Dibujo técnico e interpretación de planos",
  },
  {
    key: "inventory_management_manufacturing",
    value: "Inventory management",
    text: "Gestión de inventario",
  },
  {
    key: "material_handling",
    value: "Material handling",
    text: "Manejo de materiales",
  },
  {
    key: "safety_procedures_and_protocols",
    value: "Safety procedures and protocols",
    text: "Procedimientos y protocolos de seguridad",
  },
  {
    key: "lean_manufacturing_principles",
    value: "Lean manufacturing principles",
    text: "Principios de fabricación ajustada",
  },
  { key: "welding_manufacturing", value: "Welding", text: "Soldadura" },
  {
    key: "computer-aided_design_(cad)",
    value: "Computer-aided design (CAD)",
    text: "Diseño asistido por ordenador (CAD)",
  },
  {
    key: "programming_languages_for_industrial_automation_(such_as_ladder_logic,_c++,_or_python)",
    value:
      "Programming languages for industrial automation (such as ladder logic, C++, or Python)",
    text: "Lenguajes de programación para automatización industrial (como lógica de escalera, C ++ o Python)",
  },
  {
    key: "robotics_and_automation_technology",
    value: "Robotics and automation technology",
    text: "Robótica y tecnología de automatización",
  },
  {
    key: "troubleshooting_and_problem-solving",
    value: "Troubleshooting and problem-solving",
    text: "Solución de problemas y resolución de problemas",
  },
  {
    key: "statistical_process_control",
    value: "Statistical process control",
    text: "Control estadístico de procesos",
  },
  {
    key: "data_analysis_and_interpretation",
    value: "Data analysis and interpretation",
    text: "Análisis e interpretación de datos",
  },
  {
    key: "environmental,_health,_and_safety_regulations",
    value: "Environmental, health, and safety regulations",
    text: "Regulaciones ambientales, de salud y seguridad",
  },
  {
    key: "calibration_of_measurement_instruments",
    value: "Calibration of measurement instruments",
    text: "Calibración de instrumentos de medición",
  },
  {
    key: "electrical_wiring_and_circuitry",
    value: "Electrical wiring and circuitry",
    text: "Cableado eléctrico y circuitos",
  },
  {
    key: "pneumatic_and_hydraulic_systems",
    value: "Pneumatic and hydraulic systems",
    text: "Sistemas neumáticos e hidráulicos",
  },
  {
    key: "machining_and_tooling",
    value: "Machining and tooling",
    text: "Mecanizado y utillaje",
  },
  {
    key: "injection_molding",
    value: "Injection molding",
    text: "Moldeo por inyección",
  },
  { key: "extrusion", value: "Extrusion", text: "Extrusión" },
  {
    key: "metal_fabrication",
    value: "Metal fabrication",
    text: "Fabricación de metal",
  },
  {
    key: "assembly_line_operations",
    value: "Assembly line operations",
    text: "Operaciones de la línea de montaje",
  },
  {
    key: "packaging_and_labeling",
    value: "Packaging and labeling",
    text: "Envasado y etiquetado",
  },
  {
    key: "cnc_programming_and_operation",
    value: "CNC programming and operation",
    text: "Programación y operación CNC",
  },
  { key: "composites", value: "Composites", text: "Composites" },
  { key: "vacuum_systems", value: "Vacuum systems", text: "Sistemas de vacío" },
  {
    key: "process_improvement_methodologies_(such_as_six_sigma_or_kaizen)",
    value: "Process improvement methodologies (such as Six Sigma or Kaizen)",
    text: "Metodologías de mejora de procesos (como Six Sigma o Kaizen)",
  },
  {
    header: "true",
    key: "header-protective-services",
    text: "Servicios de protección",
    disabled: true,
  },
  {
    key: "first_aid/cpr",
    value: "First Aid/CPR",
    text: "Primeros auxilios/RCP",
  },
  {
    key: "emergency_medical_response",
    value: "Emergency medical response",
    text: "Respuesta médica de emergencia",
  },
  {
    key: "firearms_training",
    value: "Firearms training",
    text: "Entrenamiento con armas de fuego",
  },
  { key: "surveillance", value: "Surveillance", text: "Vigilancia" },
  { key: "investigation", value: "Investigation", text: "Investigación" },
  {
    key: "risk_assessment",
    value: "Risk assessment",
    text: "Evaluación de riesgos",
  },
  {
    key: "security_systems",
    value: "Security systems",
    text: "Sistemas de seguridad",
  },
  {
    key: "crisis_management",
    value: "Crisis management",
    text: "Gestión de crisis",
  },
  {
    key: "crowd_control",
    value: "Crowd control",
    text: "Control de multitudes",
  },
  {
    key: "crime_scene_investigation",
    value: "Crime scene investigation",
    text: "Investigación de la escena del crimen",
  },
  { key: "patrol", value: "Patrol", text: "Patrulla" },
  { key: "criminal_law", value: "Criminal law", text: "Derecho penal" },
  {
    key: "traffic_control",
    value: "Traffic control",
    text: "Control de tráfico",
  },
  {
    key: "criminal_justice",
    value: "Criminal justice",
    text: "Justicia penal",
  },
  { key: "public_safety", value: "Public safety", text: "Seguridad pública" },
  {
    key: "search_and_rescue",
    value: "Search and rescue",
    text: "Búsqueda y rescate",
  },
  { header: "true", key: "header-sales", text: "Ventas", disabled: true },
  { key: "prospecting", value: "Prospecting", text: "Prospección" },
  {
    key: "lead_generation",
    value: "Lead generation",
    text: "Generación de leads",
  },
  {
    key: "customer_relationship_management_(crm)",
    value: "Customer relationship management (CRM)",
    text: "Gestión de relaciones con los clientes (CRM)",
  },
  {
    key: "product_knowledge",
    value: "Product knowledge",
    text: "Conocimiento del producto",
  },
  { key: "sales_closing", value: "Sales closing", text: "Cierre de ventas" },
  {
    key: "contract_management",
    value: "Contract management",
    text: "Gestión de contratos",
  },
  {
    key: "pipeline_management",
    value: "Pipeline management",
    text: "Gestión de tuberías",
  },
  {
    key: "sales_reporting",
    value: "Sales reporting",
    text: "Informes de ventas",
  },
  {
    key: "sales_analytics",
    value: "Sales analytics",
    text: "Análisis de ventas",
  },
  {
    key: "market_research",
    value: "Market research",
    text: "Estudio de mercado",
  },
  {
    key: "competitive_analysis",
    value: "Competitive analysis",
    text: "Análisis competitivo",
  },
  {
    key: "forecasting_and_budgeting",
    value: "Forecasting and budgeting",
    text: "Previsión y presupuestación",
  },
  { key: "cold_calling", value: "Cold calling", text: "Llamadas en frío" },
  {
    key: "consultative_selling",
    value: "Consultative selling",
    text: "Venta consultiva",
  },
  {
    key: "sales_training",
    value: "Sales training",
    text: "Formación en ventas",
  },
  {
    key: "account_management",
    value: "Account management",
    text: "Gestión de cuentas",
  },
  {
    key: "pricing_analysis",
    value: "Pricing analysis",
    text: "Análisis de precios",
  },
  {
    key: "territory_management",
    value: "Territory management",
    text: "Gestión del territorio",
  },
  {
    key: "business_development_sales",
    value: "Business development",
    text: "Desarrollo de negocio",
  },
  {
    key: "sales_operations",
    value: "Sales operations",
    text: "Operaciones de venta",
  },
  {
    key: "marketing_automation",
    value: "Marketing automation",
    text: "Automatización de marketing",
  },
  {
    key: "email_marketing",
    value: "Email marketing",
    text: "Marketing por correo electrónico",
  },
  {
    key: "social_media_marketing_sales",
    value: "Social media marketing",
    text: "Marketing en redes sociales",
  },
  {
    key: "online_advertising",
    value: "Online advertising",
    text: "Publicidad online",
  },
  {
    key: "search_engine_optimization_(seo)_sales",
    value: "Search engine optimization (SEO)",
    text: "Optimización de motores de búsqueda (SEO)",
  },
  {
    key: "search_engine_marketing_(sem)",
    value: "Search engine marketing (SEM)",
    text: "Marketing en buscadores (SEM)",
  },
  {
    key: "content_marketing",
    value: "Content marketing",
    text: "Marketing de contenidos",
  },
  { header: "true", key: "header-science", text: "Ciencia", disabled: true },
  {
    key: "artificial_intelligence",
    value: "Artificial intelligence",
    text: "Inteligencia artificial",
  },
  {
    key: "r_programming_language",
    value: "R programming language",
    text: "Lenguaje de programación R",
  },
  {
    key: "behavioral_economics",
    value: "Behavioral economics",
    text: "Economía conductual",
  },
  { key: "bioinformatics", value: "Bioinformatics", text: "Bioinformática" },
  { key: "calibration", value: "Calibration", text: "Calibración" },
  {
    key: "cellular_biology_techniques",
    value: "Cellular biology techniques",
    text: "Técnicas de biología celular",
  },
  { key: "data_analysis_science", value: "Data analysis", text: "Análisis de datos" },
  {
    key: "data_interpretation",
    value: "Data interpretation",
    text: "Interpretación de los datos",
  },
  {
    key: "data_management",
    value: "Data management",
    text: "Gestión de datos",
  },
  { key: "data_mining_science", value: "Data mining", text: "Minería de datos" },
  {
    key: "data_visualization",
    value: "Data visualization",
    text: "Visualización de datos",
  },
  {
    key: "database_management",
    value: "Database management",
    text: "Gestión de bases de datos",
  },
  {
    key: "decision_analysis",
    value: "Decision analysis",
    text: "Análisis de decisiones",
  },
  {
    key: "digital_marketing",
    value: "Digital marketing",
    text: "Marketing digital",
  },
  {
    key: "econometric_modeling",
    value: "Econometric modeling",
    text: "Modelado econométrico",
  },
  {
    key: "experimental_controls",
    value: "Experimental controls",
    text: "Controles experimentales",
  },
  {
    key: "experimental_design",
    value: "Experimental design",
    text: "Diseño experimental",
  },
  {
    key: "experimental_economics",
    value: "Experimental economics",
    text: "Economía experimental",
  },
  {
    header: "true",
    key: "header-social-services",
    text: "Servicios sociales",
    disabled: true,
  },
  {
    key: "case_management",
    value: "Case management",
    text: "Gestión de casos",
  },
  { key: "counseling", value: "Counseling", text: "Asesoramiento" },
  {
    key: "crisis_intervention",
    value: "Crisis intervention",
    text: "Intervención en crisis",
  },
  {
    key: "client_assessment",
    value: "Client assessment",
    text: "Evaluación del cliente",
  },
  { key: "advocacy", value: "Advocacy", text: "Abogacía" },
  {
    key: "group_facilitation",
    value: "Group facilitation",
    text: "Facilitación de grupos",
  },
  { key: "data_entry_social_services", value: "Data entry", text: "Entrada de datos" },
  {
    key: "research_and_analysis_social_services",
    value: "Research and analysis",
    text: "Investigación y análisis",
  },
  {
    key: "grant_writing",
    value: "Grant writing",
    text: "Redacción de subvenciones",
  },
  {
    key: "program_evaluation",
    value: "Program evaluation",
    text: "Evaluación del programa",
  },
];

const PortfolioSkills = ({ isModalOpen = false, isPublicFacing }) => {
  const maxCharacterCount = 40
  const { locale } = useIntl()
  const { firebase, profile, notifier } = useContext(FirebaseContext)
  const [options, setOptions] = useState(locale === 'en-US' ? defaultOptions : defaultOptionsEs)
  const [buttonText, setButtonText] = useState(locale === 'en-US' ? "+ Add" : "+ Agregar")
  const [buttonActive, setButtonActive] = useState("inactive")
  const [currentValues, setCurrentValues] = useState([])
  // track if we've merged user's saved custom values with default values
  const [mergedValues, setMergedValues] = useState(false)

  const myRef = useRef(null)

  useEffect(() => {
    if (profile && profile.skills) {
      // The following should only be run one time.
      if (!mergedValues) {
        // set dropdown selections to existing firestore values
        setCurrentValues(profile.skills)
        mergeUserOptionsWithDefaults()
      }
    }
  }, [profile])

  useEffect(() => {
    if (profile && profile.skills) {
      setOptions(locale === 'en-US' ? defaultOptions : defaultOptionsEs)
    }
  }, [locale])

  // gets updated skills if changed in modal
  useEffect(() => {
    if (profile && profile.skills) {
      setCurrentValues(profile.skills)
      // clear all custom skills (both selected and not selected)
      setOptions(options => options.filter(option => !option.key.includes("_custom")))
      // merge custom skills that are selected
      mergeUserOptionsWithDefaults()
    }
  }, [isModalOpen])

  /**
   * Take existing firestore profile fields and merge those with
   * the default options. Failure to do this will prevent the
   * dropdown component from setting the user's saved fields as
   * initial values.
   */
  const mergeUserOptionsWithDefaults = () => {
    setMergedValues(true)
    const optionsToMerge = []
    const defaultOptionsArray = []
    // Extract value only from defaultOptions objects
    defaultOptions.map(option => {
      if (option.value) {
        defaultOptionsArray.push(option.value)
      }
    })

    // Check if each stored item is a default or custom the user created. If it is
    // custom, it must be added to the merge list so it becomes a selectable option.
    profile.skills.forEach(skill => {
      if (defaultOptionsArray.includes(skill) === false) {
        const key = skill.toLowerCase().replace(/ /g, "_")
        optionsToMerge.push({ key: key + "_custom", value: skill, text: skill })
      }
    })

    // combine default options with user's custom options.
    if (optionsToMerge.length > 0) {
      setOptions(options => [
        ...options,
        ...optionsToMerge,
      ])
    }
  }

  const handleAddition = async (e, { value }) => {
    const key = value.toLowerCase().replace(/ /g, "_")
    // item needs to be added to the default options for it to stick
    setOptions(options => [...options, { key: key + "_custom", value: value, text: value }])
    await notifier.handleSkillSetting()
  }

  const handleChange = (e, { value }) => {
    const prevLength = currentValues.length
    const newLength = value.length
    setCurrentValues(value)

    firebase.updateProfile({
      data: { skills: value },
    })

    if (newLength > prevLength) {
      // adding skill
      notifier.handleSkillSetting()
    }
  }
  const setFocus = () => {
    if (myRef && myRef.current && myRef.current.ref) {
      myRef.current.ref.current.querySelector("input:first-of-type").focus()
    }
  }

  /**
   * Handle keydown events in the drop down and prevent
   * user from exceeding a max character count.
   * @param e {SyntheticKeyboardEvent}
   */
  const handleKeyDown = e => {
    const regex = new RegExp("^[\\w\\[\\] `~!@#$%^?.,&*()={}:;<>+'\"-]{1}$")
    if (e.target.value.length >= maxCharacterCount && regex.test(e.key)) {
      e.preventDefault()
    }
  }

  const handleButtonClick = () => {
    if (buttonText === "+ Add" || buttonText === "+ Agregar") {
      setButtonText(locale === 'en-US' ? "Done" : "Hecho")
      setFocus()
      setButtonActive("active")
    } else if (buttonText === "Done" || buttonText === "Hecho") {
      setButtonText(locale === 'en-US' ? "+ Add" : "+ Agregar")
      setButtonActive("inactive")
      
    }
  }

  const handleBlur = e => {
    if (e?.relatedTarget?.innerText === "Done" || e?.relatedTarget?.innerText === "Hecho") {
      return
    }
    setButtonText(locale === 'en-US' ? "+ Add" : "+ Agregar")
    setButtonActive("inactive")
  }

  const handleFocus = e => {
    if (e?.relatedTarget?.innerText === "+ Add" || e?.relatedTarget?.innerText === "+ Agregar") {
      return
    }
    setButtonText(locale === 'en-US' ? "Done" : "Hecho")
    setButtonActive("active")
  }

  return (
    <div className="portfolio-attr-skills">
      <div className="portfolio-attr-skills-header">
        <h3>
          <FontAwesomeIcon icon={faStars} color={"#C737D3"} size={"sm"} />{" "}
          <FormattedMessage
            id="portfolio-skills-skills"
            defaultMessage="Skills"
          />
        </h3>
        {!isPublicFacing && (
          <button
            className={`button component-button component-button-ghost component-button-ghost-${buttonActive} component-button-small`}
            onClick={handleButtonClick}
          >
            {buttonText}
          </button>
        )}
      </div>
      <blockquote className="component-blockquote component-blockquote-stories printer-none">
        <p>
          <FormattedMessage
            id="portfolio-skills-skills-description"
            defaultMessage="Ask yourself, what am I good at? Sit with yourself and see what surfaces. Try to aim for no more than 20 skills"
          />
        </p>
      </blockquote>
      <div className="portfolio-attr-add paragraph">
        {profile && (
          <>
            <Dropdown
              className="printer-no-border"
              ref={myRef}
              options={options}
              value={currentValues}
              onAddItem={handleAddition}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Type here to add skills."
              fluid
              multiple
              search
              selection
              icon=""
              allowAdditions
              upward={false}
              onFocus={handleFocus}
              onBlur={handleBlur}
              renderLabel={label => ({
                className: "custom-portfolio-skills-label",
                content: label.text,
              })}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default PortfolioSkills
