export const makeGoalNotification = (points) => {
  const notification = {
    templateId: "A13",
    notificationId: null,
    isRead: false,
    isHidden: false,
    expireAfterDays: 30,
    type: "accomplishment",
    point: 1,
    heading: "Yes!",
    headingEs: "¡Sí!",
    message: `You set ${points} goal${points > 1 ? "s" : ''} and earned ${points} point${points > 1 ? "s" : ''}`,
    messageEs: `Estableciste ${points} objetivos y ganaste ${points} puntos`,
    isEmail: false,
    isToast: true,
    isInstantPointDelivery: true,
    pageLink: null,
    delaySeconds: 15,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: false,
    clearIds: ["A13"],
  }

  return notification
}

export const makeSkillNotification = (points) => {
  const notification = {
    templateId: "A5",
    notificationId: null,
    isRead: false,
    isHidden: false,
    expireAfterDays: 15,
    type: "accomplishment",
    point: 1,
    heading: "Yes!",
    headingEs: "¡Sí!",
    message: `You set ${points} skill${points > 1 ? "s" : ''} and earned ${points} point${points > 1 ? "s" : ''}`,
    messageEs: `Estableces ${points} habilidades y ganas ${points} puntos`,
    isEmail: false,
    isToast: true,
    isInstantPointDelivery: true,
    pageLink: null,
    delaySeconds: 30,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: false,
    clearIds: ["A5"],
  }

  return notification
}

export const makeInterestNotification = (points) => {
  const notification = {
    templateId: "A6",
    notificationId: null,
    isRead: false,
    isHidden: false,
    expireAfterDays: 30,
    type: "accomplishment",
    point: 1,
    heading: "Yes!",
    headingEs: "¡Sí!",
    message: `You set ${points} interest${points > 1 ? "s" : ''} and earned ${points} point${points > 1 ? "s" : ''}`,
    messageEs: `Estableciste ${points} intereses y ganaste ${points} puntos`,
    isEmail: false,
    isToast: true,
    isInstantPointDelivery: true,
    pageLink: null,
    delaySeconds: 15,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: false,
    clearIds: ["A6"],
  }

  return notification
}

export const makeNewCareerPlanNotification = (planName) => {
  const notification = {
    templateId: "A2",
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 30,
    type: "accomplishment",
    point: 10,
    heading: "Go get em!",
    headingEs: "¡Ve a por ellos!",
    message: `You created ${planName} and earned 10 points`,
    messageEs: `Has creado ${planName} y has ganado 10 puntos`,
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: false,
    clearIds: [],
  }
  return notification
}

export const makeDailyQuestCompletionNotification = (points) => {
  const notification = {
    templateId: "A1",
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 1,
    type: "accomplishment",
    point: points,
    heading: "Way to go!",
    headingEs: "¡Bien hecho!",
    message: `You completed the daily quest and earned ${points} pts.`,
    messageEs: `Completaste la misión diaria y ganaste ${points} pts.`,
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: false,
    clearIds: ["A1"],
  }
  return notification
}

export const makeStoryReadingNotification = (storyTitle, storySlug) => {
  const notification = {
    templateId: `A3-${storySlug}`,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 30,
    type: "accomplishment",
    point: 2,
    heading: "Great job!",
    headingEs: "¡Buen trabajo!",
    message: `You engaged with the story featuring ${storyTitle} and earned 2 pts.`,
    messageEs: `Te comprometiste con la historia con ${storyTitle} y ganaste 2 puntos.`,
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: true,
    clearIds: [],
  }
  return notification
}

export const makeStoryMotivationalNotification = () => {
  const notification = {
    templateId: `A1`,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 10,
    type: "motivational",
    point: 0,
    heading: "Great job!",
    headingEs: "¡Buen trabajo!",
    message: `You haven't read a story in a while. Don't give up now!`,
    messageEs: "Hace tiempo que no lees un cuento. ¡No te rindas ahora!",
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 10,
    isPermanent: false,
    clearIds: ["A1"],
  }
  return notification
}

export const makeNewBookmarkNotification = (contentType, contentTitle, contentSlug) => {
  let message = ""
  let messageEs = ""
  let heading = ""
  let headingEs = ""

  switch (contentType) {
    case "stories":
      heading = "Way to go!"
      headingEs = "¡Bien hecho!"
      message = `You bookmarked the story ${contentTitle} and earned 1 pt`
      messageEs = `Has marcado la historia como ${contentTitle} y has ganado 1 pt`
      break
    case "careers":
      heading = "Great job!"
      headingEs = "¡Buen trabajo!"
      message = `You bookmarked the career ${contentTitle} and earned 1 pt`
      messageEs = `Has marcado la carrera ${contentTitle} y has ganado 1 punto`
      break
    case "companies":
      heading = "Great job!"
      headingEs = "¡Buen trabajo!"
      message = `You bookmarked the company ${contentTitle} and earned 1 pt`
      messageEs = `Has marcado la empresa como ${contentTitle} y has ganado 1 pt`
      break
    case "blogs":
      heading = "Great job!"
      headingEs = "¡Buen trabajo!"
      message = `You bookmarked the blog post ${contentTitle} and earned 1 pt`
      messageEs = `Has marcado la entrada del blog ${contentTitle} y has ganado 1 pt`
      break
    case "majors":
      heading = "You're on your way!"
      headingEs = "¡Estás en camino!"
      message = `You bookmarked the degree ${contentTitle} and earned 1 pt`
      messageEs = `Has marcado el grado ${contentTitle} y has ganado 1 pt`
      break
    case "schools":
      heading = "Yes!"
      headingEs = "¡Sí!"
      message = `You bookmarked the school ${contentTitle} and earned 1 pt`
      messageEs = `Has marcado la escuela ${contentTitle} y has ganado 1 pt`
      break
    default:
      console.error("Invalid content type")
      return null
  }

  const notification = {
    templateId: `A10-${contentSlug}`,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 30,
    type: "accomplishment",
    point: 1,
    heading: heading,
    headingEs: "",
    message: message,
    messageEs: "",
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: true,
    clearIds: [],
  }
  return notification
}

export const makeNewResumeExperienceNotification = () => {
  const notification = {
    templateId: `A9`,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 30,
    type: "accomplishment",
    point: 1,
    heading: "Amazing!",
    headingEs: "¡Asombroso!",
    message: `You entered in your experience and earned 1 point. To export your resume to a Word doc, select More and Export Resume to Word.`,
    messageEs: "Ingresaste en tu experiencia y ganaste 1 punto. Para exportar su currículum a un documento de Word, seleccione Más y Exportar currículum a Word.",
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: false,
    clearIds: [],
  }
  return notification
}

export const makeActivityCompletionNotification = (activityTitle, activitySlug) => {
  const notification = {
    templateId: `A2-${activitySlug}`,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 30,
    type: "accomplishment",
    point: 5,
    heading: "Great job!",
    headingEs: "¡Buen trabajo!",
    message: `You completed the activity ${activityTitle} and earned 5 pts.`,
    messageEs: `Has completado la actividad ${activityTitle} y has ganado 5 puntos.`,
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: true,
    clearIds: [],
  }
  return notification
}

export const makeApproachingLevelNotification = ({ name, message, distanceToGoal }) => {
  const notification = {
    templateId: `M22-${name}`,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 30,
    type: "motivational",
    point: 0,
    heading: "Keep going!",
    headingEs: "¡Sigue!",
    message: `You are ${distanceToGoal} points away from the next level: ${name}`,
    messageEs: `Estás a ${distanceToGoal} a puntos del siguiente nivel: ${name}`,
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 5,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: true,
    clearIds: [],
  }
  return notification
}

export const makePersonalInfoNotification = () => {
  const notification = {
    templateId: `A4`,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 30,
    type: "accomplishment",
    point: 5,
    heading: "Way to go!",
    headingEs: "¡Bien hecho!",
    message: `You completed the personal information in your portfolio and earned 5 pts.`,
    messageEs: "Completaste la información personal en tu portafolio y ganaste 5 pts.",
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: true,
    clearIds: [],
  }
  return notification
}

export const makeShortTimedMotivationalNotification = (deliveredNotificationSet) => {
  let motivationalOptions = [
    {
      templateId: `M5`,
      heading: "Keep it up!",
      headingEs: "¡Seguid así!",
      message: "Take the personality assessment to get the juices flowing!",
      messageEs: "¡Haz la evaluación de personalidad para que fluyan los jugos!",
      isPermanent: true,
      expireAfterDays: 300,
      clearIds: [],
    },
    {
      templateId: `M6`,
      heading: "Keep it up!",
      headingEs: "¡Seguid así!",
      message: "Would you walk a mile in someone's shoes? Bookmark that person's story.",
      messageEs: "¿Caminarías una milla en los zapatos de alguien? Marque la historia de esa persona.",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M6`],
    },
    {
      templateId: `M7`,
      heading: "Keep it up!",
      headingEs: "¡Seguid así!",
      message: "Listen to one person's story by selecting Listen to profile under the profile pic.",
      messageEs: "Escuche la historia de una persona seleccionando Escuchar perfil debajo de la foto de perfil.",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M7`],
    },
    {
      templateId: `M8`,
      heading: "Assess your skills!",
      headingEs: "¡Evalúa tus habilidades!",
      message: "Go to a career that interests you and select the skills you already have.",
      messageEs: "Ve a una carrera que te interese y selecciona las habilidades que ya tienes.",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M8`],
    },
    {
      templateId: `M9`,
      heading: "Keep it up!",
      headingEs: "¡Seguid así!",
      message: "Add 3 SMART goals in <i>THIS WEEK</i> to help you achieve your dreams",
      messageEs: "Añade 3 objetivos SMART en ESTA SEMANA para ayudarte a alcanzar tus sueños",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M9`],
    },
    {
      templateId: `M15`,
      heading: "Get to know the world of work",
      headingEs: "Conoce el mundo laboral",
      message: "Do the Daily Quest to meet one inspiring person.",
      messageEs: "Haz la búsqueda diaria para conocer a una persona inspiradora.",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M15`],
    },
    {
      templateId: `M16`,
      heading: "Something to think about",
      headingEs: "Algo en lo que pensar",
      message: "Where do you see yourself in 10 years?",
      messageEs: "¿Dónde te ves dentro de 10 años?",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M16`],
    },
    {
      templateId: `M17`,
      heading: "Something to think about",
      headingEs: "Algo en lo que pensar",
      message: "What does success mean to you? Keep this in mind as you go through the site.",
      messageEs: "¿Qué significa el éxito para ti? Tenga esto en cuenta a medida que avanza por el sitio.",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M17`],
    },
    {
      templateId: `M19`,
      heading: "Something to think about",
      headingEs: "Algo en lo que pensar",
      message: "What makes you happy? Keep this in mind as you go through the site.",
      messageEs: "¿Qué te hace feliz? Tenga esto en cuenta a medida que avanza por el sitio.",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M19`],
    },
    {
      templateId: `M20`,
      heading: "Something to think about",
      headingEs: "Algo en lo que pensar",
      message: "What is your greatest accomplishment? Keep this in mind as you go through the site.",
      messageEs: "¿Cuál es tu mayor logro? Tenga esto en cuenta a medida que avanza por el sitio.",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M20`],
    },
  ]

  const queuedNotification = motivationalOptions.find((option) => !deliveredNotificationSet.has(option.templateId))

  if (!queuedNotification) {
    // No more motivational notifications to deliver
    return null
  }

  const notification = {
    templateId: queuedNotification.templateId,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 30,
    type: "motivational",
    point: 0,
    heading: queuedNotification.heading,
    headingEs: queuedNotification.headingEs,
    message: queuedNotification.message,
    messageEs: queuedNotification.messageEs,
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: queuedNotification.isPermanent,
    clearIds: queuedNotification.clearIds,
  }
  return notification
}

export const makeMediumTimedMotivationalNotification = (deliveredNotificationSet) => {
  let motivationalOptions = [
    {
      templateId: `M10`,
      heading: "Excel in the workplace!",
      headingEs: "¡Sobresalir en el lugar de trabajo!",
      message: "Develop your communication skills in <i>Work Readiness</i>.",
      messageEs: "Desarrolla tus habilidades de comunicación en la preparación para el trabajo.",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M10`],
    },
    {
      templateId: `M11`,
      heading: "Excel in the workplace!",
      headingEs: "¡Sobresalir en el lugar de trabajo!",
      message: "Craft your career story in <i>Work Readiness</i>.",
      messageEs: "Elabora tu historia profesional en preparación para el trabajo",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M11`],
    },
    {
      templateId: `M12`,
      heading: "Excel in the workplace!",
      headingEs: "¡Sobresalir en el lugar de trabajo!",
      message: "Cultivate a growth mindset for feedback in <i>Work Readiness</i>.",
      messageEs: "Cultive una mentalidad de crecimiento para obtener retroalimentación en la preparación para el trabajo.",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M12`],
    },
    {
      templateId: `M13`,
      heading: "Excel in the workplace!",
      headingEs: "¡Sobresalir en el lugar de trabajo!",
      message: "Succeed at time management in <i>Work Readiness</i>.",
      messageEs: "Triunfa en la gestión del tiempo en la preparación para el trabajo.",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M13`],
    },
    {
      templateId: `M14`,
      heading: "Excel in the workplace!",
      headingEs: "¡Sobresalir en el lugar de trabajo!",
      message: "Prepare for success in the workplace in <i>Work Readiness</i>.",
      messageEs: "Prepárese para el éxito en el lugar de trabajo en la preparación para el trabajo",
      isPermanent: false,
      expireAfterDays: 30,
      clearIds: [`M14`],
    },
  ]

  const queuedNotification = motivationalOptions.find((option) => !deliveredNotificationSet.has(option.templateId))

  if (!queuedNotification) {
    // No more motivational notifications to deliver
    return null
  }

  const notification = {
    templateId: queuedNotification.templateId,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 30,
    type: "motivational",
    point: 0,
    heading: queuedNotification.heading,
    headingEs: queuedNotification.headingEs,
    message: queuedNotification.message,
    messageEs: queuedNotification.messageEs,
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: queuedNotification.isPermanent,
    clearIds: queuedNotification.clearIds,
  }
  return notification
}

export const makeWelcomeNotification = () => {
  const notification = {
    templateId: `I1`,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 1000,
    type: "initial",
    point: 0,
    heading: `Welcome to Wingspans!`,
    headingEs: "Bienvenido a Wingspans",
    message: `We're on a mission to help you find a career that makes you excited to wake up in the morning. Begin your journey by selecting Get Started.`,
    messageEs:
      "Tenemos la misión de ayudarte a encontrar una carrera que te emocione al despertarte por la mañana. Comience su recorrido seleccionando Comenzar",
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: true,
    clearIds: [],
  }
  return notification
}

export const makeDailyQuestChallengeCompletionNotification = () => {
  const notification = {
    templateId: `A14`,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 5,
    type: "accomplishment",
    point: 30,
    heading: `Great Job!`,
    headingEs: "¡Buen trabajo!",
    message: `You completed the Daily Quest challenge and earned 30 points.`,
    messageEs: "Has completado el desafío de la misión diaria y has ganado 30 puntos.",
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: true,
    clearIds: ["M24"],
  }
  return notification
}

export const makeReturningUserNotification = () => {
  const notification = {
    templateId: `I2`,
    notificationId: null,
    isRead: false,
    isHidden: false,
    publicationDate: null,
    expirationDate: null,
    expireAfterDays: 1,
    type: "initial",
    point: 0,
    heading: `Welcome back!`,
    headingEs: "¡Bienvenido!",
    message: `Let's continue exploring the world of work.`,
    messageEs: "Sigamos explorando el mundo laboral.",
    isEmail: false,
    isToast: true,
    pageLink: null,
    delaySeconds: 0,
    delayMinutes: 0,
    delayHours: 0,
    delayDays: 0,
    isPermanent: false,
    clearIds: ["I2"],
  }
  return notification
}
