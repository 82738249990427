import Modal from "react-bootstrap/Modal"
import React, { useContext, useState } from "react"
import { FirebaseContext } from "../Firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import ExperienceInput from "./experience-input"
import { ExperienceEditorContext } from "./ExperienceEditorContext"
import { PortfolioContext } from "../dashboard/PortfolioContext"
import { checkRequiredFields } from "./utils/CheckRequiredFields"
import { FormattedMessage, useIntl } from "react-intl"
import PortfolioSkills from "../dashboard/portfolio-skills"
import PortfolioInterests from "../dashboard/portfolio-interests"
import { sendStandardToast } from "../toasts/standard-toast"

const ResumeExperienceEditorModal = ({ experienceData, isNewExperience, show, closeTimelineModal, type }) => {
  const { firebase, notifier, profile } = useContext(FirebaseContext)
  const { getResumeDataAsync, handleModalChange } = useContext(PortfolioContext)

  /**
   *
   * State for all form data across every type of resume experience
   *
   */

  const [experience, setExperience] = useState(isNewExperience ? { ...experienceData, type: type } : experienceData)
  const { formatMessage } = useIntl()

  const [savingSubmit, setSavingSubmit] = useState(false)
  const [savingUpdate, setSavingUpdate] = useState(false)
  const [savingDelete, setSavingDelete] = useState(false)

  const setLocalExperienceKeyValue = (key, value) => {
    setExperience({
      ...experience,
      [key]: value,
    })
  }

  const dispatchHighlightAction = (type, payload, index) => {
    let highlightsCopy = experience.highlights ?? []

    switch (type) {
      case "INITIALIZE_EMPTY_FIELDS":
        if (highlightsCopy.length === 0) {
          highlightsCopy = Array(3).fill("")
        }
        break
      case "UPDATE_INPUT_VALUE":
        highlightsCopy[index] = payload
        break
      case "DELETE_INPUT_FIELD":
        highlightsCopy.splice(index, 1)
        break
      case "CREATE_INPUT_FIELD":
        highlightsCopy.push("")
        break
    }
    setExperience({
      ...experience,
      highlights: highlightsCopy,
    })
  }

  const toggleExperienceIsCurrent = (status) => {
    if (status) {
      setExperience({
        ...experience,
        endDateMonth: null,
        endDateYear: null,
      })
    } else {
      setExperience({
        ...experience,
        endDateMonth: "",
        endDateYear: "",
      })
    }
  }

  const handleCreateResumeExperience = async (experienceData) => {
    const errors = checkRequiredFields(experience, type)
    if (errors) {
      sendStandardToast({
        message: (
          <>
          {errors.map((error) => (
            <div>{error}</div>
          ))}
        </>
        ),
        heading: <FormattedMessage id="resume-experience-editor-modal-submission-required-foll" defaultMessage="Submission requires the following" />,
      })
      return
    }

    setSavingSubmit(true)
    try {
      await firebase.createResumeExperience(experienceData)
      await getResumeDataAsync()
    } catch (e) {
      console.error(e)
    }
    setSavingSubmit(false)
    handleCloseModal()
    await notifier.handleNewResumeExperience(profile)
  }

  const handleDeleteResumeExperience = async (experienceData) => {
    setSavingDelete(true)
    try {
      await firebase.deleteResumeExperience(experienceData.id)
      await getResumeDataAsync()
    } catch (e) {
      console.error(e)
    }
    setSavingDelete(false)
    handleCloseModal()
  }

  const handleUpdateResumeExperience = async (experienceData) => {
    setSavingUpdate(true)
    try {
      await firebase.editResumeExperience(experienceData)
      await getResumeDataAsync()
    } catch (e) {
      console.error(e)
    }
    setSavingUpdate(false)
    handleCloseModal()
  }

  const handleCloseModal = () => {
    handleModalChange("CLEAR")

    // might not have callback function avaliable
    if (closeTimelineModal) {
      closeTimelineModal()
    }

    setExperience(isNewExperience ? { ...experienceData, type: type } : experienceData)
    setSavingUpdate(false)
    setSavingSubmit(false)
    setSavingDelete(false)
  }

  const renderInstructions = (type) => {
    if (!isNewExperience) {
      return <></>
    }

    switch (type) {
      case "work":
        return (
          <p style={{ fontFamily: "merriweather", fontSize: "16px" }}>
            <FormattedMessage
              id="resume-experience-editor-modal-work-instructions"
              defaultMessage="List all of your past work experiences to show off your mad job
                      skills. Include job titles, company names, location, and dates of
                      employment."
            />
          </p>
        )
      case "education":
        return (
          <p style={{ fontFamily: "merriweather", fontSize: "16px" }}>
            <FormattedMessage
              id="resume-experience-editor-modal-education-instructions"
              defaultMessage="Don't hold back, highlight your degrees, certificates, and any
                      relevant coursework."
            />
          </p>
        )
      case "award":
        return (
          <p style={{ fontFamily: "merriweather", fontSize: "16px" }}>
            <FormattedMessage
              id="resume-experience-editor-modal-award-instructions"
              defaultMessage="Set yourself apart by listing your accomplishments. This includes
                      academic awards, professional recognitions, or anything else that
                      makes you shine."
            />
          </p>
        )
      case "volunteer":
        return (
          <p style={{ fontFamily: "merriweather", fontSize: "16px" }}>
            <FormattedMessage
              id="resume-experience-editor-modal-volunteer-instructions"
              defaultMessage="Your volunteer experience speaks volumes about the kind of person
                      you are! Include the organization's name, your role, and the dates
                      of your involvement."
            />
          </p>
        )
      case "life":
        return (
          <p style={{ fontFamily: "merriweather", fontSize: "16px" }}>
            <FormattedMessage
              id="resume-experience-editor-modal-life-instructions"
              defaultMessage="Don't underestimate the value of your life experiences. List any
                      non-traditional experiences that demonstrate your skills and
                      qualifications. It could be anything from running a business to
                      moving to a new city."
            />
          </p>
        )
      case "leadership":
        return (
          <p style={{ fontFamily: "merriweather", fontSize: "16px" }}>
            <FormattedMessage
              id="resume-experience-editor-modal-leadership-instructions"
              defaultMessage=" Let the world know you are a natural-born leader by listing your
                      previous leadership roles or accomplishments."
            />
          </p>
        )
    }
  }

  return (
    <ExperienceEditorContext.Provider
      value={{
        experience,
        setLocalExperienceKeyValue,
        dispatchHighlightAction,
        checkRequiredFields,
        toggleExperienceIsCurrent,
      }}
    >
      <Modal className="editor-modal" show={show} onHide={() => handleCloseModal()} backdrop="static" size="lg" animation={false}>
        <Modal.Header closeButton>
          <div className="eyebrow component-eyebrow">
            {" "}
            {isNewExperience ? (
              <FormattedMessage id="resume-experience-editor-modal-add-title-message" defaultMessage={`Add ${type} experience`} />
            ) : (
              <FormattedMessage id="resume-experience-editor-modal-edit-title-message" defaultMessage={`Edit ${type} experience`} />
            )}
          </div>
        </Modal.Header>
        <Modal.Body className={type === "skills" || type === "interests" ? "dropdown-body" : ""}>
          {type === "skills" && <PortfolioSkills />}
          {type === "interests" && <PortfolioInterests />}
          {type !== "skills" && type !== "interests" && (
            <div>
              <div className="form career-builder-milestones-form">
                {renderInstructions(type)}
                <ExperienceInput type={type} dispatchHighlightAction={dispatchHighlightAction} />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {type === "skills" || type === "interests" ? null : isNewExperience ? (
            <>
              <button
                type="submit"
                className="component-button component-button-submit"
                onClick={() => handleCreateResumeExperience(experience)}
                style={{ overflowWrap: "normal" }}
              >
                {" "}
                {savingSubmit ? (
                  <FontAwesomeIcon color={"#FFFFFF"} icon={faSpinner} spin size="lg" />
                ) : (
                  formatMessage({ id: "resume-experience-editor-modal-save", defaultMessage: "save" })
                )}
              </button>
            </>
          ) : (
            <>
              <button
                type="submit"
                className="component-button component-button-submit-ghost"
                onClick={() => handleDeleteResumeExperience(experience)}
                style={{ overflowWrap: "normal" }}
              >
                {" "}
                {savingDelete ? (
                  <FontAwesomeIcon color={"#FFFFFF"} icon={faSpinner} spin size="lg" />
                ) : (
                  formatMessage({ id: "resume-experience-editor-modal-delete", defaultMessage: "delete" })
                )}
              </button>
              <button
                type="submit"
                className="component-button component-button-submit"
                onClick={() => handleUpdateResumeExperience(experience)}
                style={{ overflowWrap: "normal" }}
                // value="Add"
              >
                {" "}
                {savingUpdate ? (
                  <FontAwesomeIcon color={"#FFFFFF"} icon={faSpinner} spin size="lg" />
                ) : (
                  formatMessage({ id: "resume-experience-editor-modal-save", defaultMessage: "save" })
                )}
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </ExperienceEditorContext.Provider>
  )
}

export default ResumeExperienceEditorModal
